/*
    renderList() {
        return (
            <CollectionList 
                listUrl={urls.userList}
                totalCountUrl={urls.userTotal}
                objList={listObj}
                viewUrl={urls.userView}
                viewFields={viewFieldObj}
                search
                searchUrl={urls.searchUserEmail}
                searchLabel="Search by email"
                callBackOnView={this.onCollectionViewCallBack.bind(this)}

                customField
                customFieldLabel="ds" // optional
                customFieldValue="Enable/Disable"
                customFieldCallBack={this.onEnableDisable.bind(this)}

                download
                downloadObjHandler={downloadObjHandler}
                downloadValueConverterObj = {downloadValueConverter}
            />
        )
    }


    /////=====  Descriotion
    listUrl // the url to get the collections ( list )
    totalCountUrl // the url to get the total count
    objList // the object with fields to show in the list, refernce is bellow
    viewUrl // the url that will be used to get the document when clicked on read more
    viewFields // the object that is used to display the fields in the view
    delet //  OPTIONAL - a delete option will be shown
    deleteUrl // dependecy for delete - the URL to delete the row
    search: true will show a search bar
    searchUrl: url to send the search string
    searchLabel: place holder of the search bar
    callBackOnview: optional// callbak if you want to do something when readmore or view is on
    customField: this cal be used to crete a custom call back, like change a field to active or disable.
        If the user clicks on it, customFieldCallBack function will be called with the item in the param


    /////======= sample of the field objs to pass
    // this is for the list
    const listObj = [
        {   
            label: "First Name",
            field: 'firstName'
        },
        {
            label: "Date Time",
            field: {
                key: 'userId',
                nestedKeys: ['createdAt'],
                type: 'date' // optional - to convert types
            }
        },
        {
            label: "Registration Date",
            field: "createdAt",
            type: 'date'
        },
        {
            label: "Active",
            field: 'active',
            type: 'boolean'
        },
    ]

    // this is for the view
    const viewFieldObj = [
        {
            label: "First Name",
            field: 'firstName'
        },
        {
            label: "Last Name",
            field: "lastName"
        }
    ]
    

*/

import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk';
import { NavLink } from "react-router-dom";
import reducers from '../../reducers';
import {
    PostDataDirect,
    Pagination,
    stateIsTrue,
    getDataFromServerResponse,
    DocumentView,
    InputField,
    DownloadToCsv,
    DropDownField,
    getThisKeyFromAnyWhereNested,
    getDateFromMongoRes
} from '.';
import { SpinnerLine } from './Spinner';

import '../../style/css/collectionList.css';
import { urls, localUrls } from '../constant/urls';

let formDataObj = {};

class CollectionList extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            list_data: [],
            initialShowingLastIndex: 20,
            showingLastIndex: 20,
            showingStartIndex: 0,
            deleteId: '',
            refreshPagination: 1,
            showView: false,
            showViewId: '',
            requestReload: false
        };
    }

    componentWillMount() {
        const postObj = {
            "offset": 0,
            "total": this.state.showingLastIndex
        };
        this.getList(postObj)
    }

    componentDidUpdate() {
        if(stateIsTrue(this.state.requestReload)) {
            if(stateIsTrue(this.state.initialShowingLastIndex)) {
                const postObj = {
                    "offset": this.state.showingStartIndex,
                    "total": this.state.initialShowingLastIndex
                };
                this.getList(postObj);
                this.setState({
                    requestReload: false,
                    // showingLastIndex: this.state.showingStartIndex + this.state.initialShowingLastIndex
                }) 
                
            }
        }
    }

    onPagination(obj) {
        const postObj = {
            "offset": obj.offset,
            "total": obj.limit
        };
        this.getList(postObj)
        
        const updatedTotal = obj.offset + obj.limit;
        this.setState({showingLastIndex: updatedTotal})
        this.setState({showingStartIndex: obj.offset})
    }

    getList(postObj) {
        PostDataDirect(
            this.props.listUrl,
            postObj,
            this.onLoadList.bind(this)
        )
    }

    getSearch(postObj) {
        PostDataDirect(
            this.props.searchUrl,
            postObj,
            this.onLoadSearch.bind(this)
        )
    }

    onFieldUdate(obj) {
        // console.log(obj);
        formDataObj[obj.id] = {
            value: obj.value,
            error: obj.error,
        }
        
        if( obj.id === "limit"
            && obj.value
            && obj.value !== this.state.initialShowingLastIndex) {
                const newCount = this.state.refreshPagination + 1;
                this.setState({
                    initialShowingLastIndex: obj.value, 
                    requestReload: true,
                    refreshPagination: newCount
                })
        }
    }

    onLoadList(res) {
        this.setState({list_data: res})
    }

    onLoadSearch(res) {
        this.setState({list_data: res})
    }

    onDeleteButton(item) {
        this.setState({deleteId: item['_id']});
    }

    onDeleteNo() {
        this.setState({deleteId: '-'});
    }

    onDeleteYes(item) {
        const postObj = {
            id: item['_id']
        }
        PostDataDirect(
            this.props.deleteUrl,
            postObj,
            this.afterDeleteCallBack.bind(this)
        )
    }

    onReadMore(item) {
        this.setState({showView:true, showViewId:item['_id']})
        if(stateIsTrue(this.props.callBackOnView)) {
            this.props.callBackOnView(item, true);
        }
    }

    onCloseView() {
        this.setState({showView: false, showViewId: ''})
        if(stateIsTrue(this.props.callBackOnView)) {
            this.props.callBackOnView(null, false);
        }
    }

    onSearch() {
        // console.log(this);
        // console.log(formDataObj);
        // console.log('for search the key the backend is looking for should be called "search"');

        const postObj ={
            string: formDataObj.search.value
        }
        this.getSearch(postObj);
    }

    onCustsomField(item) {
        this.props.customFieldCallBack(item);
    }

    afterDeleteCallBack() {
        this.setState({deleteId:''});
        const postObj = {
            "offset": this.state.showingStartIndex,
            "total": (this.state.showingLastIndex - this.state.showingStartIndex)
        };
        this.getList(postObj)

        //upaate the prop to refresh pagination total
        const newCount = this.state.refreshPagination + 1;
        this.setState({refreshPagination: newCount})
    }

    hideInView() {
        let rv = {};
        if(this.state.showView) {
            rv = {
                display: "none"
            }
        } 

        return rv;
    }

    //renders
    renderSearch(){

        if(!stateIsTrue(this.props.search)) {
            return null;
        }

        const getPlaceHolder = () => {
            if(stateIsTrue(this.props.searchLabel)) {
                return this.props.searchLabel;
            }

            return "Search";
        }

        return (
            <div className="searchbar_wrapper" style={this.hideInView()}>
                <div className="fiedset card" >
                    <InputField 
                        id="search"
                        placeHolder={getPlaceHolder()}
                        forceValidation={this.state.validate_form}
                        callBack={this.onFieldUdate.bind(this)}
                    />
                    <div className="button" onClick={this.onSearch.bind(this)}>
                        go
                    </div>
                </div>
            </div>
        )
    }

    renderDelete(item) {
        if(stateIsTrue(this.props.delete)) {

            if(this.state.deleteId === item['_id']) {
                return (
                    <div className="cell delete_wrapper">
                        <div className="confirm" >
                            <div className="message" >
                                Are you sure you want to delete:
                            </div>
                            <div className="action" >
                                <div className="button no" onClick={this.onDeleteNo.bind(this)}>
                                    No
                                </div>
                                <div className="button yes" onClick={this.onDeleteYes.bind(this, item)}>
                                    Yes
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            return (
                <div className="cell delete_wrapper" onClick={this.onDeleteButton.bind(this, item)}>
                    Delete
                </div>
            )
        }
        return null;
    }

    renderList() {
        const handleBoolean = (string) => {
            let rv = 'No';
            if(string) rv = "Yes";
            return rv;
        }

        const handleImage = (string) => {
            return (
                <img 
                    src={string}
                    alt="string"
                />
            )
        }

        const renderItem = (item,index) => {
            const getIndex = () => {
                const rv = (index+this.state.showingStartIndex)+1;
                return rv;
            }

            const convertValue = (type, string) => {
                let rv = string;
                switch (type) {
                    case 'date':
                        rv = getDateFromMongoRes(string)
                        break;
                    case 'boolean':
                        rv = handleBoolean(string)
                        break;
                    case 'image':
                        rv = handleImage(string)
                        break;
                
                    default:
                        break;
                }

                return rv;
            }

            const renderReadmore = () => {
                if(stateIsTrue(this.props.viewUrl)) {
                    return (
                        <div className="cell" >
                            <div className="button" onClick={this.onReadMore.bind(this, item)} > 
                                Read More
                            </div>
                        </div>
                    )
                }
            }

            const renderCustomField = () => {
                if(stateIsTrue(this.props.customField)) {
                    return (
                        <div className="cell" >
                            <div className="button" onClick={this.onCustsomField.bind(this, item)} > 
                                {this.props.customFieldValue}
                            </div>
                        </div>
                    )
                }
            }

            const renderValue = (item, elem) => {
                const field = elem.field;
                let rv = item[field];
                if(elem.type) rv = convertValue(elem.type, item[field])

                if(typeof(field) === "object") {
                    const thisObj = item[field.key];
                    let nestedKey = field.nestedKeys;
                    if(typeof(nestedKey) === "object") {
                        nestedKey = nestedKey[0];
                    }
                    const foundObj = getThisKeyFromAnyWhereNested(thisObj, nestedKey)
                    let value = foundObj[nestedKey];
                    if(field.type) value = convertValue(field.type, value)

                    rv = value;
                }

                return rv;
            }

            return (
                <div key={index} className="item" >
                    <div className="cell srno" >
                        {getIndex()}
                    </div>

                    {Object.keys(this.props.objList).map((key, index) => {
                        const elem = this.props.objList[key];
                        const fieldName = elem.field;
                        
                        return (
                            <div key={index} className={`cell ${fieldName}_wrapper`} >
                                {renderValue(item, elem)}
                            </div> 
                        )
                    })}
       
                    {renderReadmore()}

                    {renderCustomField()}

                    {this.renderDelete(item)}

                </div>
            )
        }

        const renderDelete = () => {
            if(stateIsTrue(this.props.delete)) {
                return (
                    <div className="cell" >
                        <div className="" >
                            Delete
                        </div>
                    </div>
                )
            }
            return null;
        }

        const renderLabel = () => {
            const renderReadmore = () => {
                if(stateIsTrue(this.props.viewUrl)) {
                    return (
                        <div className="cell" >
                            <div className="" >
                                Read More
                            </div>
                        </div>
                    )
                }
            }

            const renderCustom = () => {
                if(stateIsTrue(this.props.customField)) {
                    return (
                        <div className="cell" >
                            <div className="" >
                                {this.props.customFieldLabel}
                            </div>
                        </div>
                    )
                }
            }

            return (
                <div className="item label" >
                    <div className="cell" >
                        srno
                    </div>
                    {Object.keys(this.props.objList).map((key, index) => {
                        const elem = this.props.objList[key];
                        const labelName = elem.label;
                        const fieldName = elem.field;
                        return (
                            <div key={index} className={`cell ${fieldName}_wrapper`} >
                                {labelName}
                            </div> 
                        )
                    })}
                    
                    {renderReadmore()}
                    {renderCustom()}
                    {renderDelete()}
                </div>
            )
        }

        const data = getDataFromServerResponse(this.state.list_data);

        if(data.success) {
            return (
                <div className="card list_wrapper " >
                    {renderLabel()}
                    {Object.keys(data.data).map((key, index) => {
                        return renderItem(data.data[key], index);
                    })}
                </div>
            )
        }
    }

    renderContent() {

        if(stateIsTrue(this.state.showView)
            && stateIsTrue(this.state.showViewId)) {
                return (
                    <div className="content_wrapper" >
                        <div className="header card" onClick={this.onCloseView.bind(this)}>
                            <div className="button" >
                                Back  
                            </div>
                        </div>
                        <DocumentView 
                            docUrl={this.props.viewUrl}
                            docId={this.state.showViewId}
                            fieldObj={this.props.viewFields}
                        />
                    </div>
                )
            }

        if(stateIsTrue(this.state.list_data)) {
            return (
                <div className="content_wrapper" >
                    {this.renderList()}
                </div>
            )
        }
        
        return (
            <div className="content_wrapper" >
                <SpinnerLine />
            </div>
        )
    }

    renderDownload() {
        if(stateIsTrue(this.props.download)
            && !stateIsTrue(this.state.showView)) {
            return (
                <DownloadToCsv 
                    data={this.state.list_data}
                    objectHandler={this.props.downloadObjHandler}
                    downloadValueConverterObj = {this.props.downloadValueConverterObj}
                />
            )
        }

        return null;
    }

    renderAdditionalFilters() {
        const limitOptions = [20, 50, 100, 200, 400, 600, 800, 1000];
        return (
            <div className="additional_filters" >
                <div className="card" >
                    <DropDownField 
                        id="limit"
                        options={limitOptions}
                        callBack={this.onFieldUdate.bind(this)}
                        placeHolder = "Total"
                        disableSlidingLabel
                    />
                </div>
            </div>
        )
    }

    renderAdditionalData() {
        if(stateIsTrue(this.props.renderAdditionalData)) {
            return (
                <div className="additionalData" >
                    {this.props.renderAdditionalData()}
                </div>
            )
        }

        return null;
    }

    render() {
        // console.log(this.state); 
        const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));
        return (
            <Provider store={store} >
                <div className="topbar" >
                    <div className="list_menu_holder" style={this.hideInView()} >
                        <Pagination 
                            countUrl={this.props.totalCountUrl} 
                            startTotal={this.state.initialShowingLastIndex}
                            callBack={this.onPagination.bind(this)}
                            refreshCount={this.state.refreshPagination}
                        />
                        {this.renderAdditionalFilters()}
                    </div>
                    {this.renderSearch()}
                    {this.renderDownload()}
                    {this.renderAdditionalData()}
                </div>
                {this.renderContent()}
            </Provider>
        );
    }
}

export {CollectionList};
