import { PostDataCustomCallBacks } from "./GetData";

const whichWayToGoWithImage = ({ img, window = true, element }) => {
    const rv = [];
    rv.style = [];
    const imageWidth = img.width;
    const imageHeight = img.height;
    let windowWidth = "";
    let windowheight = "";
    let windowRatio = "";

    if (window) {
        windowWidth = window.innerWidth;
        windowheight = window.innerHeight;
        windowRatio = windowWidth / windowheight;
    } else {
        console.log(
            "you have made window to false, but handing an element is not yer writtern in this functions"
        );
        return rv;
    }

    const parentRatio = windowRatio;
    const imageRatio = imageWidth / imageHeight;

    if (imageRatio > parentRatio) {
        //manipulate image height
        rv.style.height = windowheight;
        rv.style.width = "auto";
    } else {
        //manupilare image width
        rv.style.height = "auto";
        rv.style.width = windowWidth;
    }

    return rv;
};

const makeStringShort = (text, start = 0, length = 90) => {
    return text.substring(start, length) + " ....";
};

const inArray = (needle, heystack) => {
    let rv = false;
    if (heystack.indexOf(needle) > -1) {
        rv = true;
    }

    return rv;
};

const rowFromArray = (needle, heystack, key) => {
    for (let i = 0; i < heystack.length; i++) {
        const elem = heystack[i];
        if (elem[key] == needle) {
            return elem;
        }
    }

    return null;
};

const deleterRowFromArray = (needle, heystack, key) => {
    let rv = [];
    for (let i = 0; i < heystack.length; i++) {
        const elem = heystack[i];
        if (elem[key] != needle) {
            rv.push(elem);
        }
    }

    return rv;
};

const stateIsTrue = (key) => {
    let rv = false;
    if (key && key !== "") {
        if (typeof key === "object") {
            if (Object.keys(key).length > 0) {
                rv = true;
            }
        } else {
            rv = true;
        }
    }

    return rv;
};

const urlFriendly = (string) => {
    let rv = string.replace(" ", "-");
    rv = rv.replace("/", "-");
    return rv;
};

const isObject = (path) => {
    // console.log(path);
    const pathObj = path.split(".");
    // console.log(pathObj);
};

const isEven = (n) => {
    return n % 2 == 0;
};

const trimText = (thisString, maxLength = 200) => {
    //will trim to words
    const rv = {};
    rv.trimmed = false;
    rv.string = "";
    let trimmedString = thisString.substr(0, maxLength);
    trimmedString = trimmedString.substr(
        0,
        Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
    );

    if (thisString !== trimmedString) {
        trimmedString = `${trimmedString}...`;
        rv.trimmed = true;
    }

    rv.string = trimmedString;
    return rv;
};

const dataSuccess = (props) => {
    let rv = false;
    if (
        stateIsTrue(this.props.data) &&
        stateIsTrue(this.props.data.response) &&
        this.props.data.response.success === 1 &&
        stateIsTrue(this.props.data.response.data)
    ) {
        rv = true;
    } else {
        rv = false;
    }
};

const getDataFromServerResponse = (data) => {
    /*
        NOTE: if there is no valid data key in response, the response is false

        const data = getDataFromServerResponse(this.props);
        if(data.success) {
            
        }
    */
    const rv = {};
    rv.success = false;

    if (
        stateIsTrue(data) &&
        stateIsTrue(data.response) &&
        data.response.success === 1 &&
        stateIsTrue(data.response.data)
    ) {
        rv.success = true;
        rv.data = data.response.data;
    }

    return rv;
};

const getDataFromServerResponseNonData = (data) => {
    /*
        NOTE:  will be success if the response.success === 1, 
        It will not check if the data key exists

        const data = getDataFromServerResponse(this.props);
        if(data.success) {
            
        }
    */
    const rv = {};
    rv.success = false;

    if (
        stateIsTrue(data) &&
        stateIsTrue(data.response) &&
        data.response.success === 1
    ) {
        rv.success = true;
        rv.data = data.response.data;
        rv.res = data.response;
    }

    return rv;
};

const count = (array) => {
    let total = 0;
    Object.keys(array).map((key, index) => {
        total++;
        return null;
    });
    return total;
};

const reduceAndRandomizeArray = (array, total) => {
    // reduces an array lenght and radomizes it each time
    const length = count(array);

    if (length < total) {
        return array;
    }

    const arr = [];
    while (arr.length < total) {
        // const r = Math.floor(Math.random()*length) + 1;
        const r = Math.floor(Math.random() * length);
        if (arr.indexOf(r) === -1) arr.push(r);
    }

    const rv = [];
    Object.keys(arr).map((key, index) => {
        const selectedObj = array[arr[key]];
        rv.push(selectedObj);
        return null;
    });
    return rv;
};

const trimStringTolimit = (string, limit) => {
    const stringLength = string.length;
    if (limit > stringLength) {
        return string;
    }

    let str = string.substr(0, limit);
    str = `${str}...`;
    // console.log(str);
    return str;
};

const isEmpty = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
};

const valueInArray = (needle, heystack, field) => {
    // valueInArray(obj.id, formDataObj, "id")
    let rv = {
        result: false,
    };
    Object.keys(heystack).map((key, index) => {
        if (heystack[key][field] === needle) {
            rv.result = true;
            rv.index = index;
        }
    });
    // console.log(rv);
    return rv;
};

const convertToSingleLevelObj = (obj) => {
    /*
        This function will convert all nested key value pares ( nested to any level)
        into a single level pare

        //=== inpit obj
        const temp = {
            test: 'test',
            obj: {
                onemore: 'onemore',
                two: "two",
            },
            apple: 'apple juce',
            dog: {
                lab: "labrador"
            }
        }

        //== out put
        {
            "test": "test",
            "onemore": "onemore",
            "two": "two",
            "apple": "apple juce",
            "lab": "labrador"
        }

    */
    const rv = {};
    const scrapeThroughObj = (o, key = null) => {
        if (o && typeof o === "object") {
            Object.keys(o).map((key, index) => {
                scrapeThroughObj(o[key], key);
            });
        } else {
            rv[key] = o;
        }
    };
    scrapeThroughObj(obj);

    return rv;
};

const convertSingleLevelObjToArray = (obj) => {
    const rv = [];
    Object.keys(obj).map((key, index) => {
        rv.push(obj[key]);
    });

    return rv;
};

const getThisKeyFromAnyWhereNested = (obj, reqKey) => {
    // console.log(obj);
    // console.log(reqKey);

    const rv = {};
    const scrapeThroughObj = (o, key = null) => {
        if (o) {
            if (typeof o === "object") {
                Object.keys(o).map((key, index) => {
                    scrapeThroughObj(o[key], key);
                });
            } else {
                if (reqKey === key) {
                    rv[key] = o;
                }
            }
        } else {
            console.log("obj is null");
        }
    };
    scrapeThroughObj(obj);

    return rv;
};

const makeAnObjectFromThis = (data, fieldName) => {
    // usefull to make a drop down object from a server response
    const rv = {};
    Object.keys(data).map((key, index) => {
        const item = data[key];
        const value = item[fieldName];
        const id = item._id;
        rv[id] = data[key][fieldName];
    });
    return rv;
};

const convertFormObjToPostObj = (obj) => {
    const rv = {};
    Object.keys(obj).map((key, index) => {
        rv[key] = obj[key]["value"];
    });
    return rv;
};

const hasNumber = (myString) => {
    return /\d/.test(myString);
};

const hasLowerCase = (str) => {
    return /[a-z]/.test(str);
};

const passwordIsStrong = (string) => {
    let rv = false;
    if (string.length > 7) {
        if (hasNumber(string)) {
            rv = true;
        }
    }

    return rv;
};

const removeNonPritableValues = (str) => {
    // const str = this.removeNonPritableValues('äÄçÇéÉêPHP-MySQLöÖÐþúÚ');
    return str.replace(/[^\x20-\x7E]/g, "");
};

const removeArrayAtIndex = (obj, idx) => {
    const rv = [];
    Object.keys(obj).map((key, index) => {
        if (index !== idx) {
            rv.push(obj[key]);
        }
    });

    return rv;
};

const removeKeysFromObj = (removeKeys, sourceObj) => {
    /*
        //////==== caller:
        const tempFields = [
            'city_id',
            'city_name',
            'city_state',
            'studyingIn_id',
            'studyingIn_value'
        ]

        removeKeysFromObj(tempFields)
    */

    const rv = {};

    Object.keys(sourceObj).map((key, index) => {
        if (!inArray(key, removeKeys)) {
            rv[key] = sourceObj[key];
        }
    });

    return rv;
};

const convertDMYtoMDY = (date) => {
    const dateObj = date.split("-");
    const rvDate = `${dateObj[1]}-${dateObj[0]}-${dateObj[2]}`;
    return rvDate;
};

const authUser = (autoRedirect = false) => {
    let rv = false;
    const token = localStorage.getItem("x-auth-token");
    if (token) {
        /** authenticated use */
        rv = true;
    } else {
        /** inauthenticated user */
        if (autoRedirect) {
            window.location.href = "/";
        }
    }
    return rv;
};

const isOdd = (num) => {
    return num % 2;
};

const getUrls = () => {
    const url = [];
    url.push(window.location.href);
    url.push(window.location.hostname);
    url.push(window.location.pathname);
    url.push(window.location.protocol);
    url.push(window.location.assign);

    const rv = {
        pageName: url[2],
        data: url,
    };

    return rv;
};

const isActivePage = (string) => {
    const urlObj = getUrls();
    const pageName = urlObj.data[2];
    if (pageName == string) return true;
    return false;
};

const scrollReachedEnd = (callBack) => {
    /** this function will respond when thescroll will reach the end */
    const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
    const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
    const scrolled = winScroll / height;

    // console.log(window.innerHeight, scrolled);

    if (scrolled === 1) {
        // console.log("reached end");
        callBack();
    }
};

const isMobileDevice = () => {
    var check = false;
    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};

export {
    whichWayToGoWithImage,
    makeStringShort,
    stateIsTrue,
    inArray,
    rowFromArray,
    deleterRowFromArray,
    urlFriendly,
    isObject,
    isEven,
    trimText,
    getDataFromServerResponse,
    reduceAndRandomizeArray,
    count,
    trimStringTolimit,
    isEmpty,
    makeAnObjectFromThis,
    getDataFromServerResponseNonData,
    convertFormObjToPostObj,
    convertToSingleLevelObj,
    getThisKeyFromAnyWhereNested,
    passwordIsStrong,
    removeNonPritableValues,
    removeArrayAtIndex,
    removeKeysFromObj,
    convertDMYtoMDY,
    valueInArray,
    convertSingleLevelObjToArray,
    authUser,
    isOdd,
    getUrls,
    isActivePage,
    scrollReachedEnd,
    isMobileDevice,
};
