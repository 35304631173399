/*
    <TrimBody 
        body={item.body[0]}
        index={index}
    />

    this handles triming, show more and less options using an internal state
*/
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from '../../reducers';
import {
    trimText
} from './';

class TrimBody extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            selectedId: null,
        };
    }

    onShowMore(index) {
        this.setState({selectedId: index});
    }

    onShowLess(index) {
        this.setState({selectedId: null});
    }


    showMore(bodyObj, index) {
        if(bodyObj.trimmed) {
            return (
                <div className="showmore" onClick={this.onShowMore.bind(this, index)}>
                    Show More
                </div>
            )
        }

        return null;
    }

    showLess(index) {
        return (
            <div className="showmore" onClick={this.onShowLess.bind(this, index)}>
                Show Less
            </div>
        )
    }

    renderBody() {
        const body = this.props.body;
        const index = this.props.index;
        const bodyObj = trimText(body, 400);

        if(this.state.selectedId===index) {
            //show full
            return (
                <div className="full_body" >
                    {body}
                    {this.showLess(index)}
                </div>
            )
        }

        return (
            <div className="trimmed" >
                {bodyObj.string}
                {this.showMore(bodyObj, index)}
            </div>
        )
    }

    render() {
        const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));
        return (
            <Provider store={store} >
                {this.renderBody()}
            </Provider>
        );
    }
}

export  {TrimBody};
