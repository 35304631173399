import React, { Component } from 'react';
import {
    PhoneField,
    stateIsTrue,
    SpinnerLine,
    FormDataValidation,
    getDataFromServerResponseNonData,
    PostDataDirect
} from './';
import {urls} from '../constant/urls';
import { InputField } from './form/InputField';

let formDataObj = {};

class LoginOtpForm extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            validate_form: false,
            submitPhone: false,
            sendingOtp: false,
            collectOtp: false,
            submitOtp: false,
            submitingOtp: false,
            loginFailed: false,
            loginSuccess: false,
            selectedPhoneNumber: '',
            userNotFound: false
        };
    }

    /** life cycle */
    componentDidUpdate() {
        if(this.state.loginSuccess) {
            window.location.href = '/';
            this.setState({loginSuccess: false})
        }

        if(this.state.submitPhone) {
            this.handlePhoneSubmit();    
            this.setState({
                validate_form: false,
                submitPhone: false
            });
        }

        if(this.state.submitOtp) {
            this.handleOtpSubmit();
            this.setState({
                validate_form: false,
                submitOtp: false
            });
        }
        
    }
    
    /** user events */
    onFieldUdate(obj) {
        // console.log(obj);
        formDataObj[obj.id] = {
            value: obj.value,
            error: obj.error,
        }
    }

    OnPhoneNumber() {
        this.setState({
            validate_form: true,
            submitPhone: true
        });
    }

    OnSubmitOtp() {
        this.setState({
            validate_form: true,
            submitOtp: true
        });
    }

    onTryAgain() {
        // window.location.reload(true); 
        this.setState({
            collectOtp:true,
            loginFailed: false
        })
    }

    /** helper */
    handlePhoneSubmit() {
        const hasError = FormDataValidation(formDataObj);
        if(!hasError) {
            //validated
            const url = urls.sendOtp;
            
            const postObj = {
                phone: formDataObj.phone.value
            };

            const callBackFucntion = (res) => {
                const result = getDataFromServerResponseNonData(res);
                if(result.success) {
                    this.setState({
                        selectedPhoneNumber: formDataObj.phone.value,
                        collectOtp: true,
                        userNotFound: false
                    })
                } else {
                    this.setState({userNotFound: true})
                }
                this.setState({sendingOtp: false})
            }
            PostDataDirect(url, postObj, callBackFucntion.bind(this));
            this.setState({sendingOtp: true});
        } 
    }

    handleOtpSubmit() {
        console.log(this.state);
        const hasError = FormDataValidation(formDataObj);
        if(!hasError) {
            //validated
            const url = urls.authOtp;
            
            const postObj = {
                phone: this.state.selectedPhoneNumber,
                otp: formDataObj.otp.value
            };

            const callBackFucntion = (res) => {
                const result = getDataFromServerResponseNonData(res);
                if(result.success) {
                    this.setState({loginSuccess: true})
                    const token = result.res.token;
                    localStorage.setItem('x-auth-token', token)
                    localStorage.setItem('name', result.res.name)
                    this.setState({loginSuccess: true})
                }else {
                    this.setState({loginFailed: true})
                }
                this.setState({submitingOtp: false})
            }
            PostDataDirect(url, postObj, callBackFucntion.bind(this));
            this.setState({submitingOtp: true});
        } 
    }

    
    /** render */
    renderPhone() {

        const renderSubmit = () => {
            if(this.state.submitingOtp) {
                return (
                    <div className="submit_wrapper" >
                        <SpinnerLine />
                    </div>
                )
            }
    
            return (
                <div className="submit_wrapper" >
                    <div className="button" onClick={this.OnPhoneNumber.bind(this)}>
                        Submit
                    </div>
                </div>
            )
        }

        const renderError = () => {
            if(this.state.userNotFound) {
                return (
                    <div className="card error" >
                        This number is not registered
                    </div>  
                )  
            }

            return null;
            
        }

        return (
            <div className="group" >
                <h3> Login </h3>
                <div className="fiedset" >
                    <PhoneField 
                        id="phone"
                        placeHolder="Phone"
                        required
                        forceValidation={this.state.validate_form}
                        callBack={this.onFieldUdate.bind(this)}
                    />
                    {renderError()}
                </div>  
               {renderSubmit()}
            </div>
        )
    }

    renderOtp() {
        const renderSubmit = () => {
            if(this.state.submitingOtp) {
                return (
                    <div className="submit_wrapper" >
                        <SpinnerLine />
                    </div>
                )
            }
    
            return (
                <div className="submit_wrapper" >
                    <div className="button" onClick={this.OnSubmitOtp.bind(this)}>
                        Submit
                    </div>
                </div>
            )
        }

        return (
            <div className="group" >
                <h3> Login </h3>
                <div className="fiedset" >
                    <InputField 
                        id="otp"
                        placeHolder="otp"
                        required
                        forceValidation={this.state.validate_form}
                        callBack={this.onFieldUdate.bind(this)}
                    />
                </div>  
               {renderSubmit()}
            </div>
        )
    }

    renderFailed() {
        return (
            <div className="group" >
                <h3> Login </h3>
                <div className="fiedset" >
                    <div className="card error" >
                        <h1>Authentication failed</h1>
                    </div>
                    
                    <div className="button" onClick={this.onTryAgain.bind(this)}>
                        Try Again
                    </div>
                </div>  
            </div>
        )
    }


    renderDecide() {
        if(this.state.loginFailed) return this.renderFailed();
        
        if(this.state.collectOtp) return this.renderOtp();

        return this.renderPhone();
    }

    render() {
        return (
            <div className="formWrapper" >
                {this.renderDecide()}
            </div>
        );
    }
}

export {LoginOtpForm};
