import React from 'react';
import '../../style/css/button.css';
import { Link } from "react-router-dom";

// const rightArrowB = require('../../assets/icons/rightarrowlb.png');

const Button = (props) => {
    // console.log(props.callBack);
    if (props.callBack
        && props.callBack !== '') {
        return renderCustomButton(props)
    }
    
    return renderRouterButton(props);
};

const renderCustomButton = (props) => {
    return (
        <div 
            className="button_image" 
            onClick={props.callBack}
        >
                <div className="button_text" >
                    {props.children}        
                </div>
        </div> 
    )
}

const renderRouterButton = (props) => {
    // console.log(props);
    if (props.external) {
        return (
            <a 
                className="button_image" 
                href={props.link}
                target="_blank"
            >
                <div className="button_text" >
                    {props.children}        
                </div>
            </a> 
        )
    }
    
    return (
        <Link className="button_image" to={props.link}>
            <div className="button_text" >
                {props.children}        
            </div>
        </Link> 
    )
}

export {Button};
