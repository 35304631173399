import React from 'react';

const AuthUser = () => {
    const authToken = localStorage.getItem("token");
    if(authToken) {
        //user authorised
        return true;
    } else {
        //user not authorised
        return false
    }
}


export {AuthUser};
