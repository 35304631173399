import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Films from './Films';
import Talk from './Talk';
import About from './About';
// import My404 from './My404';

class AppRouter extends Component {
    componentDidMount() {
        const isMobile = window.innerWidth <= 500;
        if(isMobile) {
            let sPath = window.location.href;
            sPath = sPath.split("/");
            if(sPath[3] !== 'dev_error') {
                // window.location.href = '/dev_error';
            }
        } else {
          console.log('not mobile');
        }
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/" exact component={Films} />
                    <Route path="/films" exact component={Films} />
                    <Route path="/about" exact component={About} />
                    <Route path="/talk" exact component={Talk} />
                </Switch>
            </Router>
        );
    }
}

export default AppRouter;
