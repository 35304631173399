/**
 *  === CALLER
    <CountDown
        year={2019}
        month={10}
        date={3}
        hours={17}
        minutes={1}
    />
 * 
 * == CSS codes:
    .countDownComponentWrapper {
        display: flex;
        flex-direction: row;
        text-align: center;

        .cell {
            margin: 0px 5px;
            .label {
                font-size: 10px;
            }
        }
    }
 */

import React, { Component } from 'react';
import { isIntersectionTypeAnnotation } from '@babel/types';
import{
    convertDateToUsableFormats,
    getTotalDaysBetween
} from './';

class CountDown extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            intervalId: '',
            seconds: 0,
            days: 0,
            hours: 0,
            minutes: 0
        };
    }

    componentDidMount() {
        this.setTime();
        this.setInterval();
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    /** helper methods */
    setInterval() {
        var intervalId = setInterval(this.handleInterval.bind(this), 1000);
        this.setState({intervalId: intervalId});
    }

    setTime() {
        const {year, month, date, hours, minutes} = this.props;

        const sourceDate = new Date(year, month, date, hours, minutes);
        const nowDate = new Date();
        
        const sourceDateUsable = convertDateToUsableFormats(sourceDate);
        const nowDateUsabel = convertDateToUsableFormats(nowDate);
        
        /** get the ramaining */
        const totalDays = getTotalDaysBetween(nowDate, sourceDate)
        const totalHours = (Math.abs(hours - nowDateUsabel.hours)-1);
        const totalMinutes = Math.abs(minutes - (nowDateUsabel.minutes - 60));

        // console.log(totalDays, totalHours, totalMinutes, nowDateUsabel, sourceDateUsable);

        this.setState({
            days: totalDays,
            hours: totalHours,
            minutes: totalMinutes
        })
    }

    handleInterval() {
        const currentSeconds = this.state.seconds;
        let newSeconds = 60; 
        if(currentSeconds > 0) {
            newSeconds = Math.abs(currentSeconds-1);
        } else {
            this.setTime();
        }
        
        this.setState({seconds: newSeconds })
    }

    render() {
        // console.log(this.state);
        const {days, hours, minutes, seconds} = this.state;
        return (
            <div className="componentWrapper countDownComponentWrapper" >
                <div className="cell days" > 
                    <div className="value" > {days} </div>
                    <div className="label" > Days </div>
                </div>
                <div className="divider" > : </div>
                <div className="cell days" > 
                    <div className="value" > {hours} </div>
                    <div className="label" > Hours </div>
                </div>
                <div className="divider" > : </div>
                <div className="cell days" > 
                    <div className="value" > {minutes} </div>
                    <div className="label" > Minutes </div>
                </div>
                <div className="divider" > : </div>
                <div className="cell days" > 
                    <div className="value" > {seconds} </div>
                    <div className="label" > Seconds </div>
                </div>
            </div>
        );
    }
}

export {CountDown};
