import React from 'react';
// import PropTypes from 'prop-types';

//<RenderBodyLib body={item.body} />
const RenderBodyLib = ({
    body
}) => {
    // console.log(body);
    const getClassNames = (type) => {
        let rv = '';
        switch (type) {
            case 'title':
                rv = 'view_title title_header body_title'    
            break;
            case 'body':
                rv = 'view_body body'    
            break;
        
            default:
                break;
        }
        
        return rv;
    }

    return (
        Object.keys(body).map((key, index) => {
            return (
                <div key={index} className={getClassNames(body[key].type)} >
                    {body[key].value}
                </div>
            )
        })
    )
};

export {RenderBodyLib};
