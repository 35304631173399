/*
    <TimePicker 
        id='sunday_end'
        nthMinute={10}
        callBack={this.onFieldUdate.bind(this)}
        forceValidation={this.state.validate_form}
    />
*/

import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from '../../../reducers';
import {
    DropDownField,
    FormDataValidation
} from ".."
import { stateIsTrue } from '../HelperFunctions';

const formDataObj = {};

class TimePicker extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            hour_value: '',
            minute_value: 0,
            format_value: 'AM',
            submitClicked: false,
        };
    }

    componentWillReceiveProps (newProps, thisState) {
        if(stateIsTrue(newProps.forceValidation)) {
            this.onSubmit();
        }
    }

    onChangeHours(obj) {
        this.setState({hour_value: parseInt(obj.selectedKey)})
        formDataObj[obj.id] = {
            id: obj.id,
            value: obj.value,
            error: obj.error
        }
        // this.onSubmit();
    }
    onChangeMinutes(obj) {
        let selectedMinute = ((parseInt(obj.selectedKey)+1) * this.props.nthMinute);
        if(!selectedMinute) selectedMinute = 0;
        this.setState({minute_value: selectedMinute})
        formDataObj[obj.id] = {
            id: obj.id,
            value: obj.value,
            error: obj.error
        }
        // this.onSubmit();
    }
    onChangeFormat(obj) {
        let format = 'AM';
        if(parseInt(obj.selectedKey) === 1) {
            format ="PM"
        }

        this.setState({format_value: format})
        formDataObj[obj.id] = {
            id: obj.id,
            value: obj.value,
            error: obj.error
        }

        // this.onSubmit();

    }

    onSubmit() {

        const convertTo24 = () => {
            let hour =  parseInt(this.state.hour_value);
            if(this.state.format_value==='PM') {
                if(hour !== 12) {
                    hour = (hour+12);
                }
            }

            return hour;
        }
        
        this.setState({submitClicked: true})
        const hasError = FormDataValidation(formDataObj);
        const rv = {
            id: this.props.id,
            value: {
                hours: this.state.hour_value,
                minutes: this.state.minute_value,
                format: this.state.format_value,
                readable: `${this.state.hour_value}:${this.state.minute_value} ${this.state.format_value}`,
                hourIn24: convertTo24()
            },
            error: hasError
        };
        // console.log(rv);

        this.props.callBack(rv);

        // if(!hasError) {
        //     // this.props.callBack(formDataObj)
        //     // this.props.callBack(rv)
        // } else {
        //     console.log('there are errors - do not submit');
        // }
    }

    getHours() {
        let rv = [];
        for (let i = 0; i < 13; i++) {
            rv.push(i);
        }
        // console.log(rv);
        return rv;
    }

    getMinutes() {
        let rv = [];
        let runningNo = 0;

        let interval = 0;
        if(stateIsTrue(this.props.nthMinute)) {
            interval = parseInt(this.props.nthMinute);
        }
        
        for (let i = 0; i < 60; i++) {
            // console.log(`${runningNo} - ${interval} - ${i}`);
            if(runningNo === interval) {
                rv.push(i);
                runningNo = 1;
            } else {
                runningNo ++;
            }
        }
        
        return rv;
    }

    getButtonText() {
        let rv = 'Confirm';
        if(stateIsTrue(this.props.buttonText)) {
            rv= this.props.buttonText;
        }
        return rv;
    }

    render() {
        const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));

        return (
            <Provider store={store} >
                <div className="time_picker_wrapper" >
                    <div className="time_fields" >
                        <div className="picker hours_wrapper" >
                            <DropDownField
                                id="hours"
                                options={this.getHours()}
                                placeHolder = "00"
                                hidePlaceHolderIfValue
                                callBack={this.onChangeHours.bind(this)}
                                forceValidation={this.state.submitClicked}
                                required={this.props.required}
                                disableSlidingLabel
                            />      
                        </div>
                        <div className="break" >
                            :
                        </div>
                        <div className="picker minutes_wrapper" >
                            <DropDownField
                                id="minutes"
                                options={this.getMinutes()}
                                placeHolder = "00"
                                hidePlaceHolderIfValue
                                callBack={this.onChangeMinutes.bind(this)}
                                forceValidation={this.state.submitClicked}
                                // required
                                disableSlidingLabel
                            />                      
                        </div>
                        <div className="picker ampm_wrapper" >
                            <DropDownField
                                id="format"
                                options={ampm}
                                placeHolder = "Format"
                                presetToKey="0"
                                hidePlaceHolderIfValue
                                callBack={this.onChangeFormat.bind(this)}
                                forceValidation={this.state.submitClicked}
                                required
                                disableSlidingLabel
                            />                      
                        </div>
                    </div>
                    {/* <div className="button" onClick={this.onSubmit.bind(this)}>
                        {this.getButtonText()}
                    </div> */}
                </div>
            </Provider>
        );
    }
}

const ampm = [
    "AM",
    "PM"
]

export {TimePicker};
