import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from '../../../reducers';
import {
    stateIsTrue
} from '../'

class TextAreaField extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            value: '',
            error: false,
            errorMessage: "",
            showLabel: false
        };
    }

    componentDidUpdate() {
        if(!this.state.showLabel) {
            if(this.state.value !== '') {
                this.setState({showLabel: true})
            }
        }
    }

    componentWillReceiveProps (newProps) {
        // console.log(newProps);
        if(stateIsTrue(newProps.forceValidation)) {
            this.validateAndRespond(this.state.value);
        }
    }

    getPlaceHolder() {
        if(stateIsTrue(this.props.placeHolder)) {
            return this.props.placeHolder;
        }
        
        return "Message";
    }

    getClasses() {
        let classs = "input_field text_area_field";
        if(stateIsTrue(this.props.className)) {
            classs = `${classs} ${this.props.className}`;
        }

        if(this.state.error) {
            classs = `${classs} error required`;
        }

        return classs;
    }

    onChangeMethod(elem) {
        const value = elem.target.value;
        this.setState({value: value});

        this.validateAndRespond(value);
    }

    validateAndRespond(value) {
        const rv = {};
        rv.value = value;
        rv.error = false;

        if(stateIsTrue(this.props.required)
            && this.props.required) {
                if(value === '') {
                    this.setState({error:true});
                    this.setState({errorMessage: "This Field Cannot be Blank" })
                    rv.error = true;
                }else {
                    if(this.state.error) {
                        this.setState({error:false});
                        this.setState({errorMessage: ''});
                    }
                    rv.error = false;
                }
            }

        if(stateIsTrue(this.props.callBack)) {
            rv.id ="id is not specified";
            if(stateIsTrue(this.props.id)) {
                rv.id = this.props.id
            }
            this.props.callBack(rv);
        }
    }

    renderError() {

        if(this.state.error) {
            return (
                <div className="error_wrapper" >
                    {this.state.errorMessage}
                </div>
            )
        }

        return null;
    }

    renderLabel() {
        if(stateIsTrue(this.state.showLabel)) {
            return (
                <div className="label_wrapper" >
                    {this.props.placeHolder}
                </div>
            )
        }

        return null;
    }

    render() {
        // console.log(this.state);
        const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));

        return (
            <Provider store={store} >
                <div className={this.getClasses()}>
                    {this.renderLabel()}
                    <textarea 
                        type="text"
                        onChange={this.onChangeMethod.bind(this)}
                        placeholder={this.getPlaceHolder()}
                    />
                    {this.renderError()}
                </div>
                
            </Provider>
        );
    }
}

export {TextAreaField};
