import React from 'react';
import PropTypes from 'prop-types';

import './support/searchBar.css';

// const searchIcon = require("../../assets/icons/search-b.png")
const searchIcon = require("./support/images/search-b.png")

const onChange = (props) => {
    console.log('onchange');
    console.log(this);
}

const SearchBar = ({
    propsType,
    propsPlaceHolder
}) => {
    return (
        <div className="searchbar" >
            <input 
                type={propsType}
                placeholder = {propsPlaceHolder}
                className='search_bar'
                onChange={onChange.bind(this)} // this is working ulta in the caller parameter
            />
            <div className="trigger" >
                <img 
                    src={searchIcon}
                    alt="Search"
                />
            </div> 
        </div>
    );
};

export {SearchBar};
