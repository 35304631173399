import React, { Component } from 'react';
import { Header, Footer } from "./boilerplates";
import "../style/css/about.css";
import Catalogue from './Catalogue';

const bannerImage = require("../assets/images/aloke_shetty.jpg")

class About extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            stateName: ''
        };
    }

    /** lifecycle events */

    /** user events */

    /** helper functions */

    /** renders */
    renderContent() {
        return (
            <div className="aboutContentWrapper" >
                <h2>Aloke Shetty</h2>
                <div className="body" >
                    With his deft eye for performance and style along with his quirky sense of humour, Aloke Shetty brings alive every story that’s given to him. An alumini of the ‘National Institute of Design’, he won the prestigious 'Al-Jazeera Golden Award' for his documentary 'Road to America' during his formative  years.
                </div>
                <div className="body" >
                    Aloke has also won tons of metal at the prestigious Kyoorious Awards, Multiple Abby's and Maddy's and the Olive crown. His campaign for 'Times Of India' won global recognition in the form of multiple Shorty wins, INMA wins at the New York Advertising Awards.
                </div>
                <div className="body" >
                    He is a fan of works created by George Lucas, Wes Anderson and Quentin Tarantino. When not behind the camera you will find him buried in a book or in front of a gaming console (PS not X-Box!)
                </div>
            </div>
        )
    }
    renderBanner() {
        return(
            <div className="bannerWrapper" >
                <div className="image" >
                    <img 
                        src={bannerImage}
                        alt=""
                    />
                </div>
            </div>
        )
    }
    render() {
        return (
            <div className="pageWrapper aboutPageWrapper" >
                <Header /> 
                {this.renderContent()}  
                {this.renderBanner()}
                <Footer />
            </div>
        );
    }
}

export default About;
