/** 
 * <DatePickerDropDown 
        required
        id="dob"
        placeHolder="Date of birth"
        forceValidation={this.state.validate_form}
        callBack={this.onFieldUdate.bind(this)}
        startYear={2015}
        endYear={2011}
    />

    ///////=== UPDATE NOTE
    last updated on 25th oct,
        There was major bug in validation, it was passing through though the error was false
 */

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from '../../../reducers';
import { stateIsTrue } from '../HelperFunctions';
import { DropDownField, InputField } from '..';

let formDataObj = {};


class DatePickerDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            validate_form: false,
            forceValidation: false
        };
    }

    componentWillReceiveProps (newProps) {
        // console.log(newProps);
        if(stateIsTrue(newProps.forceValidation)) {
            if(this.state.forceValidation !== newProps.forceValidation) {
                this.setState({forceValidation: newProps.forceValidation});
            } 
        }
    }

    getData() {
        let startYear = 2015;
        if(stateIsTrue(this.props.startYear)) {
            startYear = this.props.startYear;
        }

        let totalyears = 70;
        if(stateIsTrue(this.props.endYear)) {
            totalyears = Math.abs(startYear - this.props.endYear);
        }

        const year = [];
        for (let index = 0; index < totalyears; index++) {
            const thisObj = {
                value: startYear-index,
                id: startYear-index
            }
            // year.push(startYear-index);
            year.push(thisObj)
        }

        const month = [];
        for (let i = 0; i < 12; i++) {
            const value = i+1;
            const thisMonth = {
                value: value,
                id: value
            }
            // month.push(i+1)
            month.push(thisMonth)
        }

        const date = [];
        for (let ii = 0; ii < 31; ii++) {
            const thisValue = ii+1;
            const thisDate = {
                value: thisValue,
                id: thisValue
            }
            // date.push(ii+1);
            date.push(thisDate);
        }
        
        const rv = {};
        rv.month = month;
        rv.year = year;
        rv.date = date;
        return rv;
    }

    onFieldUdate(obj) {
        // console.log(obj);
        formDataObj[obj.id] = {
            value: obj.value,
            error: obj.error,
        }

        this.validateAndCallBack();
    }

    validateAndCallBack() {
        const convertToDate = () => {
            let date = formDataObj.date.value;
            let month = formDataObj.month.value;
            let year = formDataObj.year.value;

            if(formDataObj.date.value) date = formDataObj.date.value.value;
            if(formDataObj.month.value) month = formDataObj.month.value.value;
            if(formDataObj.year.value) year = formDataObj.year.value.value;

            const rv = new Date(year, month-1, date);
            return rv;
        }

        if(Object.keys(formDataObj).length === 3) {
            let hasError = false;
            Object.keys(formDataObj).map((key, index) => {
                const error = formDataObj[key].error;
                if(error) {
                    hasError = true;
                }
            });

            const rv = {
                id: this.props.id,
                value: convertToDate(),
                error: hasError
            }

            this.props.callBack(rv);
        }
    }

    renderForm(){
        return (
            <div className="date_wrapper" >
                <div className="fiedset date" >
                    <DropDownField 
                        id="date"
                        placeHolder="Date"
                        options={this.getData().date}
                        skipNone
                        hidePlaceHolderIfValue
                        callBack={this.onFieldUdate.bind(this)}
                        forceValidation={this.state.forceValidation}
                        required
                    />
                </div>
                <div className="fiedset month" >
                     <DropDownField 
                        id="month"
                        placeHolder="Month"
                        options={this.getData().month}
                        skipNone
                        hidePlaceHolderIfValue
                        callBack={this.onFieldUdate.bind(this)}
                        forceValidation={this.state.forceValidation}
                        required
                    />
                </div>
                <div className="fiedset year" >
                     <DropDownField 
                        id="year"
                        placeHolder="Year"
                        options={this.getData().year}
                        skipNone
                        hidePlaceHolderIfValue
                        callBack={this.onFieldUdate.bind(this)}
                        forceValidation={this.state.forceValidation}
                        required
                    />
                </div>
            </div>
        )
    }

    render() {
        const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));

        return (
            <Provider store={store} >   
                <div className="date_dropdown_wrapper" >
                    <div className="label_wrapper" >
                        {this.props.placeHolder}
                    </div>
                    {this.renderForm()}  
                </div>
                
            </Provider>
        );
    }
}

export {DatePickerDropDown};
