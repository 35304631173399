export const TESTING_FIRST_REDUCER = 'testing_first_reducer';

//submit
export const SUBMIT_CREATE_USER = 'submit_create_user';

//auth
export const AUTH_LOGIN = 'auth_login';
export const AUTH_RESET = 'auth_reset';

//fetch
export const FETCH_MERCHANT_LIST = 'fetch_merchant_list';
export const FETCH_MERCHANT_VIEW = 'fetch_merchant_view';