import {
    AUTH_LOGIN,
    AUTH_RESET
  } from '../actions/Types';

const INITIAL_STATE = {
    reducerAuthRes: '',
};

export default(state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTH_LOGIN:      
            return { ...state, 
            reducerAuthRes: action.payload
        };
        
        case AUTH_RESET:      
            return { ...state, 
            reducerAuthRes: ''
        };
  
        default:
        return state;
    }
};