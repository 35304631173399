import React, { Component } from 'react';
// import LandingMain from './LandingMain';
import AppRouter from './AppRouter'
import '../style/css/boilerPlate.css';
import '../style/css/boilerPlateResp.css';
import '../style/css/master.css';
import '../style/css/masterResp.css';

class App extends Component {
  render() {
    return (
      <div className="App">
          <AppRouter />
      </div>
    );
  }
}

export default App;
