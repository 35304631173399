import React, { Component } from 'react';
import "../../style/css/footer.css";
import { NavLink } from 'react-router-dom';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            stateName: ''
        };
    }

    /** lifecycle events */

    /** user events */

    /** helper functions */

    /** renders */
    renderAbout() {
        return (
            <div className="item about" >
                <h2>About</h2>
                <div className="body" >
                    Nam porttitor blandit accumsan. Ut vel dictum sem, a pretium dui. In malesuada enim in dolor euismod, id commodo mi consectetur. Curabitur at vestibulum nisi. 
                </div>
            </div>
        )
    }

    renderContact() {
        return (
            <div className="item contact" >
                <h2>Contact</h2>
                <div className="body" >
                    +91 9886829700
                </div>
                <div className="" >
                    aloke.shetty@gmail.com
                </div>
            </div>
        )
    }

    renderFollow() {
        return (
            <div className="item follow" >
                <h2>Follow</h2>
                <a href="https://www.instagram.com/alokeshetty/?hl=en" target="_blank" className="body" >
                    Insta
                </a>
                <a href="https://www.facebook.com/aloke.shetty.9" target="_blank" className="body" >
                    Facebook
                </a>
            </div>
        )
    }

    renderMenu() {
        return (
            <div className="item menu" >
                <NavLink to="/talk"><h2>Talk</h2></NavLink>
                <NavLink to="/about"><h2>About</h2></NavLink>
                <NavLink to="/films"><h2>Films</h2></NavLink>
            </div>
        )
    }

    render() {
        return (
            <div className="footerWrapper" >
                {/* {this.renderAbout()} */}
                {this.renderContact()}
                {this.renderFollow()}
                {this.renderMenu()}
            </div>
        );
    }
}

export {Footer};
