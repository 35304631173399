import React, { Component } from "react";
import "../style/css/catalogue.css";
import {
    PostDataDirect,
    getDataFromServerResponse,
    SpinnerLine,
    Pagination,
    CloudFlareThumbnail,
} from "./common";
import { urls } from "./constant/urls";
import { isOdd, scrollReachedEnd, isMobileDevice } from "../components/common";
// import YoutubeThumbnail from "./common/YoutubeThumbnail";

const prevIcon = require("../assets/icons/prev.png");
const nextIcon = require("../assets/icons/next.png");

class Catalogue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filmsDataReady: false,
            filmsData: {},
            skip: 0,
            limit: 12,
            paginationRefreshCount: 0,
            getNewData: false,
            isMobile: false,
        };
    }

    /** lifecycle events */
    componentDidMount() {
        this.getFilms();
        window.addEventListener("scroll", () =>
            scrollReachedEnd(this.onScrollEnd.bind(this))
        );
        this.setState({ isMobile: isMobileDevice() });
    }

    componentDidUpdate() {
        if (this.state.getNewData) {
            this.getFilms();
            this.setState({ getNewData: false });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", () =>
            scrollReachedEnd(this.onScrollEnd.bind(this))
        );
    }

    /** user events */
    onPagination(event) {
        // console.log(event);
        this.setState({
            skip: event.skip,
            limit: event.limit,
            getNewData: true,
        });
    }

    onScrollEnd = () => {
        console.log("reached rned");
        const skip = this.state.skip + this.state.limit;
        this.setState({
            skip: skip,
            getNewData: true,
        });
    };

    /** helper functions */
    getFilms() {
        const url = urls.films;
        const postObj = {
            skip: this.state.skip,
            limit: this.state.limit,
        };

        const callBackFucntion = (res) => {
            const result = getDataFromServerResponse(res);
            if (result.success) {
                const data = result.data;

                const currentData = this.state.filmsData;
                let finalData = data;
                if (currentData[0]) finalData = currentData.concat(data);

                // console.log(finalData, data);
                this.setState({
                    filmsDataReady: true,
                    filmsData: finalData,
                });
            }
        };
        PostDataDirect(url, postObj, callBackFucntion.bind(this));
    }

    /** renders */
    renderThumb() {
        const data = this.state.filmsData;

        const renderItem = (item, index) => {
            const getClass = () => {
                const rv = isOdd(index) === 1 ? "even" : "odd";
                return rv;
            };
            return (
                <div key={index} className={`item ${getClass()}`}>
                    <CloudFlareThumbnail item={item} index={index} />
                </div>
            );
        };

        return (
            <div className="thumb_wrapper">
                {Object.keys(data).map((key, index) => {
                    return renderItem(data[key], index);
                })}
                <i aria-hidden="true" />
                <i aria-hidden="true" />
            </div>
        );
    }

    renderPagination() {
        if (!this.state.isMobile) return null;
        return (
            <div className="paginationShell">
                <Pagination
                    countUrl={urls.filmsTotal}
                    refreshCount={this.state.paginationRefreshCount}
                    startTotal={this.state.limit}
                    nextIcon={nextIcon}
                    prevIcon={prevIcon}
                    callBack={this.onPagination.bind(this)}
                />
            </div>
        );
    }

    renderDecide() {
        if (this.state.filmsDataReady) {
            return (
                <div className="shell">
                    {this.renderThumb()}
                    {this.renderPagination()}
                </div>
            );
        }
        return (
            <div className="centerFlexContent">
                <SpinnerLine />
            </div>
        );
    }

    render() {
        return <div className="catalogueWrapper">{this.renderDecide()}</div>;
    }
}

const dataHold = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default Catalogue;
