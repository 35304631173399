import React, { Component } from "react";
import YouTube from "react-youtube";
import {
    PostDataDirect,
    getDataFromServerResponse,
    SpinnerLine,
} from "./common";
import { urls } from "./constant/urls";
import { Stream } from "@cloudflare/stream-react";

const bannerImage = require("../assets/images/banner.jpg");
const playIcon = require("../assets/icons/play.png");

class FilmBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filmsDataReady: false,
            filmsData: {},
            pausedAt: 0,
        };
    }

    /** lifecycle events */
    componentDidMount() {
        this.getFilms();
    }

    /** user events */
    onPlay() {
        this.setState({ playVideo: true });
    }

    onPause(event) {
        const pausedTime = event.target.getCurrentTime();
        this.setState({
            playVideo: false,
            pausedAt: Math.round(pausedTime),
        });
    }

    onEnd() {
        this.setState({
            playVideo: false,
            pausedAt: 0,
        });
    }

    /** helper functions */
    getFilms() {
        const url = urls.filmsBanner;
        const postObj = {};

        const callBackFucntion = (res) => {
            const result = getDataFromServerResponse(res);
            if (result.success) {
                const data = result.data;
                this.setState({
                    filmsDataReady: true,
                    filmsData: data,
                });
            }
        };
        PostDataDirect(url, postObj, callBackFucntion.bind(this));
    }

    /** renders */
    renderBanner() {
        const item = this.state.filmsData[0];

        if (this.state.filmsDataReady) {
            return (
                <div className="bannerWrapper">
                    <div className="image">
                        <img
                            src={`${urls.imagePath}films/banner/${item.bannerImage}`}
                            alt=""
                        />
                    </div>
                    <div className="bannerDesc">
                        <h2>{item.title}</h2>
                        <p>{item.description}</p>
                    </div>
                    <div
                        className="playWrapper"
                        onClick={this.onPlay.bind(this)}
                    >
                        <img src={playIcon} alt="" />
                    </div>
                </div>
            );
        }

        return (
            <div className="bannerWrapper">
                <div className="image centerFlexContent">
                    <SpinnerLine />
                </div>
                <div className="bannerDesc">
                    <h2>...</h2>
                    <p>...</p>
                </div>
                <div
                    className="playWrapper"
                    onClick={this.onPlay.bind(this)}
                ></div>
            </div>
        );
    }

    renderVideo() {
        const item = this.state.filmsData[0];

        const opts = {
            // height: '500px',
            // width: '100%',
            playerVars: {
                autoplay: 1,
                rel: 1,
                modestbranding: 1,
                start: this.state.pausedAt,
                color: "white",
            },
        };

        const getClass = () => {
            return this.state.playVideo
                ? "bannerWrapper videoPlaying animated fadeIn"
                : "bannerWrapper";
        };

        return (
            <div className={getClass()}>
                <Stream
                    controls
                    src={item.cloudFlareId}
                    autoplay
                    onEnded={this.onEnd.bind(this)}
                    // height="80%"
                />
            </div>
        );

        // return (
        //     <div className="bannerWrapper">
        //         <YouTube
        //             videoId={item.youtubeId}
        //             opts={opts}
        //             onReady={this._onReady}
        //             containerClassName="testingClass"
        //             onEnd={this.onEnd.bind(this)}
        //             onPause={this.onPause.bind(this)}
        //         />
        //     </div>
        // );
    }

    renderDecide() {
        if (this.state.playVideo) return this.renderVideo();

        return this.renderBanner();
    }

    render() {
        return this.renderDecide();
    }
}

export default FilmBanner;
