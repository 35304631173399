import {isEmpty} from "../";

const FormDataValidation = (value) => {
    let rv = false;
    
    const props = value;
    Object.keys(props).map((key, index) => {
        if(props[key].error) {
            rv = true
        }
    });

    if(isEmpty(props)) {
        rv = true;
    }

    return rv;
}

export {FormDataValidation};
