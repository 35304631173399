import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from '../../reducers';
import { 
    stateIsTrue,
    validateFormData
} from './'


// import '../../style/css/formStyle.css';

class FieldSelect extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            showOptions: false,
            value: '',
            selectedKey: '',
        };
    }

    onShowOptions() {
        const showing = this.state.showOptions;
        let rv = true;
        if( showing ) {
            rv = false;
        }
        this.setState({showOptions: rv})
    }

    onSelectOption(key, options, thisNow) {
        thisNow.setState({value: options[key]})
        thisNow.setState({selectedKey: key})
        thisNow.setState({showOptions: false})
        const runtimeValidationRes = this.runtimeValidation(thisNow.props, key)
        thisNow.props.callBack(runtimeValidationRes);
    }

    runtimeValidation(props, value) {
        if(!props.parent.error) {
            const res = [];
            res.summary = [];
            res.summary.error = false;
            return res;
        }
        const fieldKey = props.fieldKey;
        const fieldSet = props.parent.field_set;
        const fieldObj = [];
        fieldObj[fieldKey] = fieldSet[fieldKey];
        const newValueObj = [];
        newValueObj[fieldKey] = value;
        const thisFieldErrorRes = validateFormData(newValueObj, fieldObj);

        const validationRes = [];
        validationRes.thisField = thisFieldErrorRes
        
        const oldErrorData = props.parent.error_res;
        if(stateIsTrue(oldErrorData)) {
            const completeErrorData = oldErrorData;
            const thisFieldData = thisFieldErrorRes.data[fieldKey];
            if(stateIsTrue(thisFieldData)) {
                if(!stateIsTrue(completeErrorData.data)) {
                    completeErrorData.data = [];
                }
                completeErrorData.data[fieldKey] = thisFieldData;
                validationRes.completeErrorRes = completeErrorData;
            }else {
                // there is no error in the selected field
                validationRes.completeErrorRes = oldErrorData; 
                if(stateIsTrue(validationRes.completeErrorRes.data)
                    && stateIsTrue(validationRes.completeErrorRes.data[fieldKey])) {
                        delete validationRes.completeErrorRes.data[fieldKey];
                }
            }
        } else {
            validationRes.completeErrorRes = thisFieldErrorRes;
        }
        
        return validationRes;
    }

    getIconClass() {
        let className = 'icon_wrapper';
        const showing = this.state.showOptions;
        if ( showing ) {
            className = 'icon_wrapper icon_up';
        }
        return className;
    }

    getClassName() {
        let className = 'select_wrapper';
        if(this.getErrorState()) {
            className = `${className} error`;
        }

        return className;
    }

    getErrorState() {
        let rv = false;
        if(stateIsTrue(this.props.errorData)
            && stateIsTrue(this.props.errorData.data)) {
            const obj = this.props.errorData.data;
            if(stateIsTrue(obj[this.props.fieldKey])) {
                rv = true;
            }
        }
        return rv;
    }
 
    renderOptions() {
        const { options } = this.props;
        if(this.state.showOptions) {
            return (
                <div className="option_wrapper">
                    {Object.keys(options).map((key, index) => {
                        return (
                            <div 
                                className="option" 
                                key={index}
                                onClick={() => this.onSelectOption(key, options, this)}
                            >
                                {options[key]}
                            </div>
                        );
                    })}
                </div>
            );
        }

        return null;
        
    }

    renderValue() {
        if ( this.state.value === '') {
            return (
                <div className="placeholder" >
                    Select one
                </div>
            );
        }

        return (
            <div className="selected_value" >
                {this.state.value}
            </div>
        );
        
    }

    renderInputField() {
        return(
            <div 
                className="input_field"
                value = "testing"
                onClick={this.onShowOptions.bind(this)}
            >   
                <div className="value" >
                    {this.renderValue()}  
                </div>
                
                <div className={this.getIconClass()} >
                    <img 
                        src={this.props.icon}
                        alt='v'
                    />
                </div>
            </div>
        );
    }

    renderErrorField() {
        if( this.getErrorState()
            && !this.state.showOptions) {
            return (
                <div className="error_field" >
                    {this.props.errorData.data[this.props.fieldKey].message}
                </div>
            )
        }
    }

    render() {
        // console.log(this.state);
        const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));

        return (
            <Provider store={store} >
                <div className={this.getClassName()} >
                    {this.renderInputField()}
                    {this.renderOptions()}
                </div>
                {this.renderErrorField()}
                
            </Provider>
        );
    }
}

// const options = {
//     option1: 'option 1 value',
//     option2: 'option 2 value option 2 value',
//     option3: 'option 3 value',
// }

export {FieldSelect};
