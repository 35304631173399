/*
    // add this styling in your generic
    .calendar {
        .callendar_wraper {
            width: 100%;
            min-width: 330px;
            .month_year_wraper {
                padding: 10px;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                .month, .year{
                    padding: 0px 10px;
                }
            }
            .days_label {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                .item {
                    text-align: center;
                    padding: 10px;
                }
            }
            .days_wraper {
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                .item {
                    text-align: center;
                    padding: 10px;
                }
            }
        }
    }
*/

import React from 'react';
import {
    convertDateToUnix, 
    shortMonthName,
    shortDayNames,
    convertDateToUsableFormats,
} from './DateHelper';
import './support/calendar.css';
import { stateIsTrue } from './HelperFunctions';

// return the data only
const CalendarData = ({
    date = new Date(),
    month = date.getMonth(),
    year = date.getFullYear(),
}) => {
    const monthData = getMontDetails(month, year);
    const calObj = makeCalendarObject(monthData);
    return calObj;
}

// return jsx
const RenderCalendarComponent = (date, month, year, callBack) => {
    const monthData = getMontDetails(month, year);
    const calObj = makeCalendarObject(monthData);
    // console.log(calObj, month, year);
    // return renderData(calObj, month, year, callBack);
    return renderData(calObj, month, year, callBack);
}

const RenderCalendar = ({
    date = new Date(),
    month = date.getMonth(),
    year = date.getFullYear(),
    callBack=''
}) => {
    const monthData = getMontDetails(month, year);
    const calObj = makeCalendarObject(monthData);
    // console.log(calObj, month, year);
    // return renderData(calObj, month, year, callBack);
    return renderData(calObj, month, year, callBack);
}


//-- helper functions
const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
}
const getFirstDay = (month, year) => {
    return new Date(year, month, 1);
}
const getLastDay = (month, year) => {
    return new Date(year, month + 1, 0);
}
const getMontDetails = (month, year) => {
    const totalDaysOfTheMonth = daysInMonth(month, year);
    const firstDay = getFirstDay(month, year);
    const firstDayNum = firstDay.getDay();
    const lastDay = getLastDay(month, year);
    const lastDayNum = lastDay.getDay();
    
    const rv = {
        totalDaysOfTheMonth: totalDaysOfTheMonth,
        firstDay: firstDay,
        firstDayNum: firstDayNum,
        lastDay: lastDay,
        lastDayNum: lastDayNum,
        month: month,
        year: year,
    }

    return rv;
}
const getDayDetails = (month, year, date) => {
    return new Date(year, month, date);
}

const makeCalendarObject = (monthDetails) => {
    const calendarObj = [];
    
    const setInitialBlanks = () => {
        const firstDay = (monthDetails.firstDayNum);
        for (let index = 0; index < firstDay; index++) {
            calendarObj.push({
                class:'blank'
            })
        }
    }
    const setLaterBlanks = () => {
        const lastDay = monthDetails.lastDayNum;
        const remainder = 6 - lastDay;
        for (let index = 0; index < remainder; index++) {
            calendarObj.push({
                class:'blank',
                data: monthDetails.firstDay,
            })
        }
    }
    const setDates = () => {
        const m = monthDetails.month;
        const y = monthDetails.year;
        const totalDays = monthDetails.totalDaysOfTheMonth;

        for (let ii = 0; ii < totalDays; ii++) {
            const dt = (ii+1);
            const fullDate = getDayDetails(m,y,dt);
            const d = fullDate.getDay();
            calendarObj.push({
                class:'date',
                date: dt,
                month: m,
                year: y,
                monthName: shortMonthName[monthDetails.month],
                dateFull: fullDate, 
                day: d,
                dayName: shortDayNames[d],
            })
        }    
    }

    const getData = () => {
        setInitialBlanks();
        setDates();
        setLaterBlanks();
    }
    
    getData();

    return calendarObj;
    
}

const onDate = (elem, callBack) => {
    // console.log(elem);
    if(stateIsTrue(callBack)) {
        callBack(elem);
    }
}

const renderData = (calObj, month, year, callBack) => {
    const renderMonth = () => {
        return (
            <div className="month_year_wraper" >
                <div className="month" >
                    {shortMonthName[month]}
                </div>
                <div className="gap" >
                    -
                </div>
                <div className="year" >
                    {year}
                </div>
            </div>
        )
    }

    const renderDaysLabel = () => {
        const renderItem = (item, key) => {
            return (
                <div key={key} className="item" >
                    {item}
                </div>
            )
        }
        
        return (
            <div className="days_label" >
                {Object.keys(shortDayNames).map((key, index) => {
                    return renderItem(shortDayNames[key], key)
                })}
            </div>
        )
    }

    const renderDays = () => {
        const isFuture = (item) => {
            let rv = false;
            const itemDate = convertDateToUnix(new Date(item.year, item.month, item.date));
            const nowDate = convertDateToUnix(new Date());
            
            if(nowDate < itemDate) {
                rv = true;
            }
            return rv;
        }

        const isToday = (item) => {
            const todayRow = new Date();
            const today = convertDateToUsableFormats(todayRow);
            
            const itemYear = parseInt(item.year);
            const itemDay = parseInt(item.date);
            const itemMonth = parseInt(item.month);

            const nowDay = parseInt(today.date);
            const nowMonth = parseInt(today.month);
            const nowYear = parseInt(today.year);

            if(nowDay === itemDay 
                && nowMonth === itemMonth
                && nowYear === itemYear) {
                    return true;
                } else {
                    return false;
                }


            // console.log(
            //     itemMonth,
            //     itemDay,
            //     itemYear,
            //     nowDay,
            //     nowMonth,
            //     nowYear
            // );

            // console.log(item, today);
        }

        const setFuturePastClass = (item) => {
            let className = 'past';
            if(isFuture(item)) {
                className = 'future'
            } else {
                if(isToday(item)) {
                    className = "today"
                }
            }

            return className;
        }

        const isActive = (item) => {
            let rv = false;
            if(isFuture(item)) {
                rv = true;
            }

            if(isToday(item)) {
                rv = true;
            }
            return rv;
        }
        const renderItem = (item, key) => {
            if(isActive(item)) {
                return (
                    <div 
                        key={key} 
                        day={item.day} 
                        dayname={item.dayName}
                        className={`item ${item.class} ${setFuturePastClass(item)} `} 
                        onClick={() => onDate(item, callBack)}
                    >
                        {item.date}
                    </div>
                )
            } 

            return (
                <div 
                    key={key} 
                    day={item.day} 
                    dayname={item.dayName}
                    className={`item ${item.class} ${setFuturePastClass(item)} disabled`} 
                >
                    {item.date}
                </div>
            )
            
        }

        return (
            <div className="days_wraper" >
                {Object.keys(calObj).map((key, index) => {
                    return renderItem(calObj[key], key);
                })}
            </div>
        )
    }

    return (
        <div className="callendar_wraper" >
            {renderMonth()}
            {renderDaysLabel()}
            {renderDays()}
        </div>
    );
}


export {
    CalendarData, 
    RenderCalendar, 
    RenderCalendarComponent
}; 