const fullMonthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];
const shortMonthName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

const fullDayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const shortDayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const datePostfix = ['st','nd','rd','th','th','th','th','th','th','th','th','th','th','th','th','th','th','th','th','th','st','nd','rd','th','th','th','th','th','th','th','st']

////=== from calender componene
const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
}
const getFirstDay = (month, year) => {
    return new Date(year, month, 1);
}
const getLastDay = (month, year) => {
    return new Date(year, month + 1, 0);
}
////== end from calender component
const convertTo24HoursFormat = (time) => {
    // console.log(time);
    const split1 = time.split(':');
    if(split1[2]) {
        const s2 = split1[2].split(' ');
        let addValue = 0;
        if(s2[1]) {
            if( s2[1]==="AM"
                || s2[1] === "am") {
                addValue = 0;
            } else {
                addValue = 12;
            }
        }

        const newHours = parseInt(split1[0])+addValue;
        const rv = {};
        rv.string = `${newHours}:${split1[1]}`;
        rv.obj = {
            hours: newHours,
            minutes: parseInt(split1[1])
        }
        return rv;
    }

    return null;
}

const convertDateToUsableFormats = (dateObj) => {
    // convertDateToUsableFormats(new Date());
    try {
        dateObj.getDate();
    } catch (err) {
        console.log('date is not a date object in form helper, the error details re bellow');
        console.log(err);
        return false;
    } 

    const d = dateObj.getDate();
    const y = dateObj.getFullYear();
    const m = dateObj.getMonth();
    const h = dateObj.getHours();
    const mt = dateObj.getMinutes();

    const toString = dateObj.toDateString();

    let forHumanSmall = toString.split(" ");
    const year = forHumanSmall.pop();
    forHumanSmall = `${forHumanSmall[0]} ${forHumanSmall[1]} ${forHumanSmall[2]}`;
    
    const rtObject = [];
    rtObject.local = dateObj.toLocaleString();
    rtObject.readable = dateObj.toDateString();
    rtObject.readableMini = forHumanSmall;
    rtObject.year = year;
    rtObject.mysql = y + '-' + m + '-' + d;
    rtObject.mysqlTime = h + '-' + mt;
    rtObject.readableIndia = `${d}/${m}/${y}`;
    rtObject.readableHuman1 = `${d}${datePostfix[d-1]} ${shortMonthName[m]}`;
    rtObject.readableHuman2 = `${fullDayNames[dateObj.getDay()]}, ${d}${datePostfix[d-1]} ${fullMonthNames[m]}`;
    rtObject.readableHumanShortMonth = `${fullDayNames[dateObj.getDay()]}, ${d}${datePostfix[d-1]} ${shortMonthName[m]}`;
    rtObject.readableTime = h + ':' + mt;
    rtObject.timeString = dateObj.toTimeString();
    rtObject.localTimeString = dateObj.toLocaleTimeString();
    rtObject.localTime24HoursString = convertTo24HoursFormat(dateObj.toLocaleTimeString());
    rtObject.asis = dateObj;
    rtObject.date = d;
    rtObject.month = m;
    rtObject.hours = h;
    rtObject.minutes = mt;
    rtObject.day = dateObj.getDay();
    rtObject.dayString = fullDayNames[dateObj.getDay()];
    rtObject.dayStringShort = shortDayNames[dateObj.getDay()];
    rtObject.monthString = fullMonthNames[m];
    rtObject.monthStringShort = shortMonthName[m];


    return rtObject;
};

const getNextMonth = (month, year, key) => {    
    let newMonth = month;
    let propYear = year;
    
    if (month > 10) {
        newMonth = 0;
        propYear++;
    } else {
        newMonth++;
    }
    const obj = [];
    obj.key = key;
    obj.month = newMonth;
    obj.monthName = fullMonthNames[newMonth];
    obj.monthShortName = shortMonthName[newMonth];
    obj.year = propYear;
    return obj;
};

const getPrevMonth = (month, year, key) => {
    let newMonth = month;
    let propYear = year;
    
    if (month === 0) {
        newMonth = 11;
        propYear--;
    } else {
        newMonth--;
    }
    const obj = [];
    obj.key = key;
    obj.month = newMonth;
    obj.monthName = fullMonthNames[newMonth];
    obj.monthShortName = shortMonthName[newMonth];
    obj.year = propYear;
    return obj;
};

const convertDateToUnix = (date) => {
    return parseInt((date.getTime() / 1000).toFixed(0));
};

const convertUnixToDate = (value) => {
    return new Date(value * 1000);
};

const convertSecondsToRedableDateTime = (seconds) => {
    // initiate totals
    const totalSeconds = Math.floor(Math.abs(seconds));
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);
    const totalDays = Math.floor(totalHours / 24);

    //get ramaining hours after day conversion
    const totalDaysToHours = totalDays * 24;
    const remainderHours = totalHours - totalDaysToHours;

    // get remaining minutes after hour conversion
    const totalHourstoMinutes = totalHours * 60;
    const remainingMinutes = totalMinutes - totalHourstoMinutes;
    
    // functions to decide what to render
    const getDays = (long = true) => {
        const value = totalDays;
        if (value < 1) return '';

        if (long) {
            if (value > 1) return `${value} Days,`;
            return `${value} Day,`;
        } 

        return `${value} D,`;
    };

    const getHours = (long = true) => {
        const value = remainderHours;
        if (value < 1) return '';

        if (long) {
            if (value > 1) return `${value} Hours,`;
            return `${value} Hour,`;
        } 

        return `${value} H,`;
    };

    const getMinutes = (long = true) => {
        const value = remainingMinutes;
        if (value < 1) return '';

        if (long) {
            if (value > 1) return `${value} Minutes`;
            return `${value} Minute`;
        } 

        return `${value} M`;
    };

    const handleBlank = (value, long) => {
        if (value === ''
            || !value.replace(/\s/g, '').length) {
                if (long) return 'Less Than a Minute';
                return 'Just now';
            }
        
            return value;
    };

    const readableLong = handleBlank(
                                `${getDays()} ${getHours()} ${getMinutes()}`, 
                                true
                            );
    const readableShort = handleBlank(
                                `${getDays(false)} ${getHours(false)} ${getMinutes(false)}`, 
                                false
                            );

    const rv = {
        readable_long: readableLong,
        readable_short: readableShort,
    };

    
    const mathRv = {
        key_totalSeconds: totalSeconds,
        key_totalMinutes: totalMinutes,
        key_totalHours: totalHours,
        key_totalDays: totalDays,
        key_totalDaysToHours: totalDaysToHours,
        key_remainderHours: remainderHours,
        key_totalHourstoMinutes: totalHourstoMinutes,
        key_remainingMinutes: remainingMinutes
    };  

    rv.debug = mathRv;
    // console.log(rv);
    return rv;   
};

const getDateFromMongoRes = (isoDate) => {
    const date = new Date(isoDate);
    // const rv = date.getFullYear()+'-' + (date.getMonth()+1) + '-'+date.getDate();
    const rv = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`
    return rv;
}

const getDateFromMongoResFull = (isoDate) => {
    const date = new Date(isoDate);
    // const rv = date.getFullYear()+'-' + (date.getMonth()+1) + '-'+date.getDate();
    const rv = convertDateToUsableFormats(date);
    return rv;
}

const getDayPlusFromToday = (number, date=new Date()) => {
    /** get {var} days from now (now + 3) get {var} days from {var} or (var + 3)
     *  
     * Caller
     * const vDate = getDayPlusFromToday(3, temp);
     *                  or
     * const vDate = getDayPlusFromToday(3,);
    */
    date.setDate(date.getDate() + number);
    return date;
}

const get1MonthFromNow = (dateObj = new Date()) => {
    /** Caller
        const tDate = new Date(2019, 0, 31)//y/m/d;
        const getOneMontFromNow = get1MonthFromNow(tDate);
        
        this handles all the short/long months as well. so 31 if feb could be 28
    */
    // var dateObj = new Date();
    // console.log(dateObj);
    const d = dateObj.getDate();
    const y = dateObj.getFullYear();
    const m = dateObj.getMonth();
    const nextMonthObj = getNextMonth(m, y);
    const nextMonth = (nextMonthObj.month);
    
    const nextMonthDays = daysInMonth(nextMonth+1, y);

    const returnYear = nextMonthObj.year;
    let returnDate = d;
    if(d > nextMonthDays) {
        /** next month doesnt have that many days 
         * so bring dow the request date to the end
         * of the requested month
        */
       console.log('reached 0');
        returnDate = nextMonthDays;
    }

    // console.log('giev month = ', m);
    // console.log('next month = ', nextMonth);
    // console.log('given days = ', d);
    // console.log('final days = ', returnDate);
    // console.log('nextMonthDays = ', nextMonthDays);
    
    const rv = new Date(returnYear, nextMonth, returnDate);
    return rv;
}

const getManymonthsFromNow = (number, dateObj = new Date()) => {
    /** 
     * - default will get {var} months from now
     * - if date provided then {var} months from the date
     * 
     * Caller:
     *  const get3montsFromNow = getManymonthsFromNow(3) 
     *          or
     *  const get3montsFromNow = getManymonthsFromNow(3, dateObj)
     * */

    let runningDate = dateObj;
    for (let index = 0; index < number; index++) {
        runningDate = get1MonthFromNow(runningDate)
    }

    return runningDate;

}

const dateIsInFuture = (dateObj) => {
    const nowDate = new Date();
    // console.log(nowDate, dateObj);
    if(!dateObj)  console.log('ERROR: dateObj Param missing');
    if(Date.parse(nowDate) < Date.parse(dateObj)){
        //start is less than End
        return true;
    }else{
        //end is less than start
        return false;
    }
}

const getTotalDaysBetween = (d1, d2) => {
    const diff = Math.abs(d1 - d2);
    const ONEDAY = 1000 * 60 * 60 * 24;
    const diffInDays = Math.round(diff/ONEDAY);
    return diffInDays;
}

const getAge = (birthday) => { // birthday is a date
    var ageDifMs = Date.now() - birthday;
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

module.exports.convertDateToUsableFormats  = convertDateToUsableFormats;
module.exports.getNextMonth  = getNextMonth;
module.exports.getPrevMonth  = getPrevMonth;
module.exports.shortMonthName  = shortMonthName;
module.exports.fullMonthNames = fullMonthNames;
module.exports.convertDateToUnix = convertDateToUnix;
module.exports.convertUnixToDate = convertUnixToDate;
module.exports.fullDayNames = fullDayNames;
module.exports.shortDayNames = shortDayNames;
module.exports.convertSecondsToRedableDateTime = convertSecondsToRedableDateTime;
module.exports.getDateFromMongoRes = getDateFromMongoRes;
module.exports.getDateFromMongoResFull = getDateFromMongoResFull;
module.exports.getDayPlusFromToday = getDayPlusFromToday;
module.exports.get1MonthFromNow = get1MonthFromNow;
module.exports.getManymonthsFromNow = getManymonthsFromNow;
module.exports.dateIsInFuture = dateIsInFuture;
module.exports.getTotalDaysBetween = getTotalDaysBetween;
module.exports.getAge = getAge;