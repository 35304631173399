import React, { Component } from 'react';
import{
    convertDateToUsableFormats,
    RenderCalendarComponent,
} from './';
import { stateIsTrue } from './HelperFunctions';

const backNav = require("./support/back.svg");

class Calendar extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            placeHolder: 'Select Date',
            showCalendar: false,
            date: '',
            month: '',
            year: '',
            selectedDate: [],
            returnObj: {},
            handleCallBack: false,
            placeholder: true,
            error: false
        };
    }

    //lifecycle
    componentDidMount() {
        this.init();
    }

    componentDidUpdate() {
        if(this.state.handleCallBack) {
            this.handleCallBack();
            this.setState({handleCallBack: false})
        }
    }
    
    componentWillReceiveProps (newProps) {
        // console.log(newProps);
        if(stateIsTrue(newProps.forceValidation)) {
            this.handleCallBack();
        }
    }

    //user events
    onInputClick() {
        this.setState({showCalendar: true})
    }

    onNextMonth() {
        let value = this.state.month;
        if(value === 11) value = -1;
        this.setState({month: (value+1)})
    }
    onPrevMonth() {
        let value = this.state.month;
        if(value === 0) value = 12;
        this.setState({month: (value-1)})
    }

    onDate(elem) {
        const elemReadableDate = convertDateToUsableFormats(elem.dateFull);
        
        const rv = {
            value: elem.dateFull,
            error: false,
            id: this.props.id
        }

        this.setState({
            showCalendar: false,
            selectedDate: elem,
            returnObj: rv,
            handleCallBack: true,
            placeHolder: elemReadableDate.readable,
            placeholder: false
        });

    }

    //helperfunction
    init() {
        const nowDateObj = new Date();
        const nowDate = convertDateToUsableFormats(nowDateObj);

        this.setState({
            date: nowDate,
            month: nowDate.month,
            year: nowDate.year,
        })

        
    this.setState({placeHolder: `Today ${nowDate.readableMini}`})
    }

    handleCallBack() {
        const rv = this.state.returnObj;
        if(!stateIsTrue(this.state.selectedDate)) {
            if(this.props.required) {
                rv.error = true;
                rv.id = this.props.id;
                rv.value = '';
            }
            this.setState({error:true})
        } else {
            this.setState({error: false})
        }

        this.props.callBack(rv);
    }

    renderCalendarMake() {
        return RenderCalendarComponent(
                this.state.date, 
                this.state.month,
                this.state.year,
                this.onDate.bind(this)
            );
    }

    renderNav() {
        return (
            <div className="nav" >
                <div className="back" onClick={this.onPrevMonth.bind(this)}>
                      <img 
                          src={backNav}
                          alt=""
                      />
                </div>
                <div className="next" onClick={this.onNextMonth.bind(this)}>
                      <img 
                          src={backNav}
                          alt=""
                      />
                </div>
            </div>
        )
    }

    renderError() {
        if(this.state.error) {
            return (
                <div className="error_wrapper error" >
                    Select a date
                </div>
            )
        }
    }

    renderDecide() {
        if(this.state.showCalendar) {
            return (
                <div className="cal" >
                    {this.renderNav()}
                    {this.renderCalendarMake()}
                </div>
            )
        }

        const getInputClass = () => {
            if(this.state.placeholder) {
                return 'placeholder';
            }
            return 'value'
        }

        return (
            <div className={`input inputField ${getInputClass()}`} onClick={this.onInputClick.bind(this)}>
                {this.state.placeHolder}
            </div>
        )
    }

    render() {
        return (
            <div className="calendarCaller" >
                {this.renderDecide()}
                {this.renderError()}
            </div>
        );
    }
}

export {Calendar};
