import React, { Component } from 'react';
import { Header, Footer } from "./boilerplates";
import "../style/css/talk.css";
import TalkForm from './TalkForm';

class Talk extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            stateName: ''
        };
    }

    /** lifecycle events */

    /** user events */

    /** helper functions */

    /** renders */
    renderContent() {
        return (
            <div className="talkContentWrapper" >
                <h2>Ice Breaker 101</h2>
                <div className="body" >
                    You know, sometimes all you need is 20 kbps , literally 20 Kbps of internet speed to say hello and I promise you, something great (30 seconder, 45 seconder, 60 seconder, 90 seconder - including language dubs*) will come of it. <br/> So drop a line or call or whatsapp or whatever floats your 🐐, stranger :)
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="pageWrapper talkPageWrapper" >
                <Header /> 
                {this.renderContent()}  
                <TalkForm />
                <Footer />
            </div>
        );
    }
}

export default Talk;
