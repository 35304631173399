/**
    <Thumbnail 
        item={item}
        index={index}
    />

*== item should have the data structure similar to:
    title: "vieo title"
    thumbnailImage: "image.jpg"
    description: "description text"
    youtubeId: "t5F85S-ekFk"
*==
    This will use a custom thumbnail
    onPlay will render the cloudflare video
 */

import React, { Component } from "react";
import YouTube from "react-youtube";
import { urls } from "../constant/urls";
import { Stream } from "@cloudflare/stream-react";

// const playIcon = require("../assets/icons/play.png")
const playIcon = require("./support/icons/play.png");

class CloudFlareThumbnail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showVideo: false,
            pausedAt: 0,
        };
    }

    /** lifecycle events */

    /** user events */
    onPlay() {
        // console.log(this.props.item);
        this.setState({ showVideo: true });
    }

    onEnd() {
        this.setState({
            showVideo: false,
            pausedAt: 0,
        });
    }
    onPause(event) {
        // console.log(event.target);
        let pausedTime = event.target.getCurrentTime();
        const videoDuration = event.target.getDuration();
        if (pausedTime >= videoDuration) pausedTime = 0;
        this.setState({
            showVideo: false,
            pausedAt: Math.round(pausedTime),
        });
    }

    /** helper functions */

    /** renders */
    renderItem() {
        const { item, index } = this.props;

        const opts = {
            width: "100%",
            height: "300px",
            playerVars: {
                autoplay: 1,
                rel: 1,
                modestbranding: 1,
                start: this.state.pausedAt,
                color: "white",
            },
        };

        if (this.state.showVideo) {
            // const videoIdOrSignedUrl = "5d5bc37ffcf54c9b82e996823bffbb81";
            // const videoIdOrSignedUrl = "a599621837e996dac095dc2049fcf254";
            return (
                <div className="holder animated fadeIn">
                    <div className="thumbnail">
                        <Stream
                            controls
                            src={item.cloudFlareId}
                            autoplay
                            onEnded={this.onEnd.bind(this)}
                        />
                    </div>
                    <h2 className="caption">{item.title}</h2>
                    <div className="body">{item.description}</div>
                </div>
            );
        }

        return (
            <div className="holder">
                <div className="thumbnail videoThumb">
                    <div className="thumbImage">
                        <img
                            src={`${urls.imagePath}films/thumb/${item.thumbnailImage}`}
                            alt=""
                        />
                    </div>
                    <div
                        className="playWrapper"
                        onClick={this.onPlay.bind(this)}
                    >
                        <img src={playIcon} alt="" />
                    </div>
                </div>
                <div className="caption">{item.title}</div>
            </div>
        );
    }

    render() {
        return this.renderItem();
    }
}

export { CloudFlareThumbnail };
