/**
 *  <LightBox 
        data={this.state.LightBoxData} 
        closeCallBack={this.onCloseLightBox.bind(this)}
    />
 */
import React, { Component } from 'react';
import '../../style/css/lightbox.css';

const closeIcon = require("./support/icons/close-w.png");
const prevIcon = require("./support/icons/prev-w.png");
const nextIcon = require("./support/icons/next-w.png");

class LightBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showingIndex: 0
        };
    }

    componentDidMount() {
        this.setState({showingIndex: this.props.data.index})
    }

    componentDidUpdate() {
        if(this.props.data.index !== this.state.showingIndex) {
            // this.setState({showingIndex: this.props.data.index})
        }
        // console.log(this.state.showingIndex, '<- new showinginede');
    }

    //user eve
    onClose() {
        this.props.closeCallBack();
    }

    onNext() {
        const obj = this.props.data.obj;
        const length = obj.length;
        if(this.state.showingIndex >= length-1) {
            /** end has reached */
            this.setState({showingIndex: 0})
        } else {
            const rn = this.state.showingIndex+1;
            this.setState({showingIndex: rn})
        }
    }

    onPrev() {
        const obj = this.props.data.obj;
        const length = obj.length;
        if(this.state.showingIndex === 0) {
            /** end has reached */
            this.setState({showingIndex: length-1})
        } else {
            const rn = this.state.showingIndex-1;
            this.setState({showingIndex: rn})
        }
    }

    renderLightBox() {
        const imageUrl = this.props.data.url;
        const data = this.props.data.obj;
        const src = `${imageUrl}/${data[this.state.showingIndex]}`;
        return (
            <div className="lightBoxContainer" >
                <div className="imageContainer">
                    <img 
                        src={src}
                        alt=""
                    />
                </div>
            </div>
        )
    }

    renderCloseIcon() {
        return (    
            <div className="closeIcon" onClick={this.onClose.bind(this)} />
        )
    }

    renderNext() {
        return (
            <div className="nextIcon" onClick={this.onNext.bind(this)} />
        )
    }

    renderPrev() {
        return (
            <div className="prevIcon" onClick={this.onPrev.bind(this)} />
        )
    }

    render() {
        // console.log(this.state, this.props);
        return (
            <div className="lightBoxWrapper" >
                {this.renderCloseIcon()}
                {this.renderNext()}
                {this.renderPrev()}
                {this.renderLightBox()}
            </div>
        );
    }
}

export {LightBox};
