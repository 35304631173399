import React, { Component } from 'react';
import { DropDownField, AutoCompleteDb } from "../";

class DropDownAutoComplete extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            validate_form: '',
            dropDownisShowing: false,
            forceHideOptions: false
        };
    }

    onFieldUdate() {

    }

    onDropDownShow(rv) {
        // console.log('cakked');
        const value = !this.state.dropDownisShowing;
        this.setState({dropDownisShowing: value})
    }

    onAutocompleteFieldChange(obj) {
        this.setState({forceHideOptions: true})
    }

    renderDropdown() {
        return (
            <DropDownField 
                options={this.props.options}
                // required
                callBack={this.handleValue}
                // icon={downIcon}
                placeHolder = "Any Neighbourhood"
                hidePlaceHolderIfValue
                // presetToKey="0"
                forceValidation={this.state.validate_form}
                disableSlidingLabel
                callBackWhenDropDownIsShowing={this.onDropDownShow.bind(this)}
                // skipNone
                noneText={this.props.noneText}
                forceHideOptions={this.state.forceHideOptions}
            />
        )
    }

    renderAutocomplete() {
        if(!this.state.dropDownisShowing) return null;
        
        return (
            <AutoCompleteDb
                id="intitution"
                placeHolder="Search by location"
                // required
                forceValidation={this.state.validate_form}
                callBack={this.onFieldUdate.bind(this)}
                callBackForFieldChange={this.onAutocompleteFieldChange.bind(this)}
                field="name"
                searchUrl={this.props.searchUrl}
                donotShowlabel

            />
        )
    }

    render() {
        // console.log(this.state);
        return (
            <div className="dropDownAutoCompleteWrapper" >
                {this.renderDropdown()}
                {this.renderAutocomplete()}
            </div>
        );
    }
}

const options =[
    {
        id: "easy",
        value: "Easy"
    },
    {
        id: "intermediate",
        value: "Intermediate"
    },
]

export {DropDownAutoComplete};
