/**
    <CalendarWithQuickSelect 
        id="bookingDate"
        callBack={this.onFieldUdate.bind(this)}
        forceValidation={this.state.validate_form}
        required
        useThisDropOptions={calendarDropDownOptions}
        // showOptionsOnLoad
        onOutsideClickCallBack={this.onOutsideClickOfCalendar.bind(this)}
    />
 */

import React, { Component } from 'react';
import{
    convertDateToUsableFormats,
    RenderCalendarComponent,
    DropDownField
} from '.';
import { stateIsTrue } from './HelperFunctions';

const backNav = require("./support/back.svg");

class CalendarWithQuickSelect extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            placeHolder: 'Select Date',
            showCalendar: false,
            date: '',
            month: '',
            year: '',
            selectedDate: [],
            returnObj: {},
            handleCallBack: false,
            placeholder: true,
            error: false,
            dropdownOptions: {},
            validate_form: false,
            updateDisplay: false,
            presetKey: "0",
            firstCalenderPush:false
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.calenderContentRef = this.calenderContentRef.bind(this);
    }

    //lifecycle
    componentDidMount() {
        this.init();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate() {
        if(this.state.handleCallBack) {
            this.handleCallBack();
            this.setState({handleCallBack: false})
        }

        if(this.state.updateDisplay) {
            this.updateOptions();
            this.setState({updateDisplay: false})
        }
    }
    
    componentWillReceiveProps (newProps) {
        // console.log(newProps);
        if(stateIsTrue(newProps.forceValidation)) {
            this.handleCallBack();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    //user events
    onInputClick() {
        // this.setState({showCalendar: true})
    }

    onShowCalendar() {
        const value = !this.state.showCalendar;
        this.setState({
            showCalendar: value,
            // updateDisplay: true
        })
    }

    onNextMonth() {
        let value = this.state.month;
        if(value === 11) value = -1;
        this.setState({month: (value+1)})
    }
    onPrevMonth() {
        let value = this.state.month;
        if(value === 0) value = 12;
        this.setState({month: (value-1)})
    }

    onDate(elem) {
        // console.log('on date', elem);
        const elemReadableDate = convertDateToUsableFormats(elem.dateFull);
        
        const rv = {
            value: elem.dateFull,
            error: false,
            id: this.props.id
        }

        this.setState({
            showCalendar: false,
            selectedDate: elem,
            returnObj: rv,
            handleCallBack: true,
            placeHolder: elemReadableDate.readable,
            placeholder: false,
            updateDisplay: true
        });
    }

    onDropDownDate(elem) {
        if( !elem.value ||
            !elem.value.dateFull) return null;
        /** the above line is true if clicked on the calendar */
        // console.log(elem.value);
        const elemReadableDate = convertDateToUsableFormats(elem.value.dateFull);
        
        const rv = {
            value: elem.value.dateFull,
            error: false,
            id: this.props.id
        }

        this.setState({
            // showCalendar: false,
            selectedDate: elem,
            returnObj: rv,
            handleCallBack: true,
            placeHolder: elemReadableDate.readable,
            placeholder: false,
            // updateDisplay: true
        });
    }

    //helper function
    init() {
        const nowDateObj = new Date();
        const nowDate = convertDateToUsableFormats(nowDateObj);
        
        this.createOptions();

        this.setState({
            date: nowDate,
            month: nowDate.month,
            year: nowDate.year,
        })

        this.setState({placeHolder: `Today ${nowDate.readableMini}`})
    }

    updateOptions() {
        let calElem = this.state.selectedDate;
        if(!stateIsTrue(this.state.selectedDate)
            || this.state.showCalendar) {
            var todayRow = new Date();
            var today = convertDateToUsableFormats(todayRow)
            calElem = {
                date: today.date,
                month: today.month,
                year: today.year
            }
        }
        
        const value = `${calElem.date}/${calElem.month}/${calElem.year}`;
        const options = this.state.dropdownOptions;
        const data = {
            id: 'selectedDate',
            value: value
        }

        options.push(data);
        const dataInkey = options.length -1;
        
        this.setState({
            dropdownOptions: options,
            presetKey: dataInkey,
            validate_form: true
        })
        
    }

    createOptions() {

        if(stateIsTrue(this.props.useThisDropOptions)) {
            this.setState({dropdownOptions: this.props.useThisDropOptions})
            return null;
        }

        /** default of today tomorrow if no custom options are passed */
        console.log('no custom options - so using default');
        let today = new Date();
        let tomorrow = new Date();
        tomorrow.setDate(today.getDate()+1);
        let dayAfter = new Date();
        dayAfter.setDate(today.getDate()+2);
        
        today = convertDateToUsableFormats(today);
        tomorrow = convertDateToUsableFormats(tomorrow);
        dayAfter = convertDateToUsableFormats(dayAfter);

        const op = [
            {
                id: 'today',
                value: `Today ${today.readableHuman1}`,
                dateFull: today.asis
            },
            {
                id: 'tomorrow',
                value: `Tomorrow ${tomorrow.readableHuman1}`,
                dateFull: tomorrow.asis
            },
            {
                id: 'dayAfter',
                value: `Tow Days from now ${dayAfter.readableHuman1}`,
                dateFull: dayAfter.asis
            },
        ];
        
        this.setState({dropdownOptions: op})
    }

    handleCallBack() {
        const rv = this.state.returnObj;
        if(!stateIsTrue(this.state.selectedDate)) {
            if(this.props.required) {
                rv.error = true;
                rv.id = this.props.id;
                rv.value = '';
            }
            this.setState({error:true})
        } else {
            this.setState({error: false})
        }

        this.props.callBack(rv);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }
    calenderContentRef(node) {
        this.calWrapperRef = node;
    }

    handleClickOutside(event) {
        // console.log(this.wrapperRef );
        // console.log(event.target);
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if(this.state.showCalendar) {
                if(!this.calWrapperRef.contains(event.target)) {
                    /** the click is not on the cal */
                    this.setState({showCalendar: false})
                    if(this.props.onOutsideClickCallBack) this.props.onOutsideClickCallBack();
                }
            }
        }
    }


    /** renders */
    renderCalendarMake() {
        return RenderCalendarComponent(
                this.state.date, 
                this.state.month,
                this.state.year,
                this.onDate.bind(this)
            );
    }

    renderNav() {
        return (
            <div className="nav" >
                <div className="back" onClick={this.onPrevMonth.bind(this)}>
                      <img 
                          src={backNav}
                          alt=""
                      />
                </div>
                <div className="next" onClick={this.onNextMonth.bind(this)}>
                      <img 
                          src={backNav}
                          alt=""
                      />
                </div>
            </div>
        )
    }

    renderError() {
        if(this.state.error) {
            return (
                <div className="error_wrapper error" >
                    Select a date
                </div>
            )
        }
    }

    renderCalendar() {
        if(this.state.showCalendar) {
            return (
                <div className="calendarWrapper" ref={this.calenderContentRef} >
                    {this.renderNav()}
                    {this.renderCalendarMake()}
                </div>
            )
        }

        return null;
    }

    renderDecide() {
        const getInputClass = () => {
            if(this.state.placeholder) {
                return 'placeholder';
            }
            return 'value'
        }

        const getPresetKey = () => {

            if(stateIsTrue(this.props.noPreset)) {
                return '';
            }

            return this.state.presetKey;
        }
        
        let showOptionsOnLoadReq = false;
        if(stateIsTrue(this.props.showOptionsOnLoad)) showOptionsOnLoadReq = true;

        return (
            <div className="genericStyling" >
                <DropDownField 
                    id='quickPick'
                    options={this.state.dropdownOptions}
                    skipNone
                    callBack={this.onDropDownDate.bind(this)}
                    forceValidation={this.state.validate_form}
                    // icon={calendarIcon}
                    placeHolder = "Select date"
                    hidePlaceHolderIfValue
                    presetToKey={getPresetKey()}
                    disableSlidingLabel
                    showOptionsOnLoad={showOptionsOnLoadReq} 
                    onOutsideClickCallBack={this.props.onOutsideClickCallBack}
                />
                <div
                    ref={this.setWrapperRef}
                    className="calendarQuickSelectLauncher" 
                    onClick={this.onShowCalendar.bind(this)}
                />
            </div>
            
        )
    }

    render() {
        // console.log(this.props, this.state);
        return (
            <div className="calendarCaller callenderQuickSelectWrapper" >
                {this.renderDecide()}
                {this.renderCalendar()}
                {this.renderError()}
            </div>
        );
    }
}

export {CalendarWithQuickSelect};
