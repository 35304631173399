import { combineReducers } from 'redux';
// import TempReducer from './TempReducer';
// import FetchReducer from './FetchReducer';
import SubmitReducer from './SubmitReducer';
import AuthReducer from './AuthReducer';
import FetchReducer from './FetchReducer';


export default combineReducers({
    submitReducer: SubmitReducer,
    authReducer: AuthReducer,
    fetchReducer: FetchReducer
});
