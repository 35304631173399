/*
    <InputField 
        id="question"
        placeHolder="Question"
        required
        forceValidation={this.state.validate_form}
        callBack={this.onFieldUdate.bind(this)}
        defaultValue="some value"
        // donotShowlabel
    />
*/

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from '../../../reducers';
import {
    stateIsTrue,
    convertDateToUnix
} from '../'

class InputField extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            value: '',
            error: false,
            errorMessage: "",
            showLabel: false,
            maxlength: 100
        };
    }

    componentDidMount() {
        if(stateIsTrue(this.props.maxlength)) {
            this.setState({maxlength: this.props.maxlength})
        }
    }

    componentDidUpdate() {
        if(!this.state.showLabel) {
            if(this.state.value !== '') {
                if(!this.props.donotShowlabel) {
                    this.setState({showLabel: true})
                }
            }
        }
    }

    getType() {
        let rv = 'text';
        if(stateIsTrue(this.props.type)) {
            rv = this.props.type;
        }

        return rv;
    }

    getId(){
        if(stateIsTrue(this.props.id)) {
            return this.props.id;
        }

        return new Date();
    }

    componentWillReceiveProps (newProps) {
        // console.log(newProps);
        if(stateIsTrue(newProps.forceValidation)) {
            this.validateAndRespond(this.state.value);
        }
    }

    getPlaceHolder() {
        if(stateIsTrue(this.props.placeHolder)) {
            return this.props.placeHolder;
        }
        
        return "Enter Text";
    }

    getClasses() {
        let classs = "input_field text_field";
        if(stateIsTrue(this.props.className)) {
            classs = `${classs} ${this.props.className}`;
        }

        if(this.state.error) {
            classs = `${classs} error required`;
        }

        return classs;
    }

    onChangeMethod(elem) {
        const value = elem.target.value;
        this.setState({value: value})

        this.validateAndRespond(value);
    }

    validateAndRespond(v) {

        // update the value so force validation doesnt show default value as error
        let value = v;
        if(!value && stateIsTrue(this.props.defaultValue)) {
            value = this.props.defaultValue;
            this.setState({value:value})
        }

        const rv = {};
        rv.value = value;
        rv.error = false;

        let errorMessageText = "This field cannot be blank";
        if(stateIsTrue(this.props.errorMessageText)) errorMessageText = this.props.errorMessageText;

        if(stateIsTrue(this.props.required)
            && this.props.required) {
                if(value === '') {
                    this.setState({error:true});
                    this.setState({errorMessage: errorMessageText })
                    rv.error = true;
                }else {
                    if(this.state.error) {
                        this.setState({error:false});
                        this.setState({errorMessage: ''});
                    }
                    rv.error = false;
                }
            }

        if(stateIsTrue(this.props.callBack)) {
            // rv.id ="id is not specified";
            rv.id = convertDateToUnix(new Date());
            if(stateIsTrue(this.props.id)) {
                rv.id = this.props.id
            }
            
            this.props.callBack(rv);
        }
    }

    renderError() {

        if(this.state.error) {
            return (
                <div className="error_wrapper animated fadeIn" >
                    {this.state.errorMessage}
                </div>
            )
        }

        return null;
    }

    renderLabel() {
        if(stateIsTrue(this.state.showLabel)) {
            return (
                <div className="label_wrapper animated slideInUp" >
                    {this.props.placeHolder}
                </div>
            )
        }

        return null;
    }

    render() {
        // console.log(this.props, this.state);
        const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));

        return (
            <Provider store={store} >
                <div className={this.getClasses()}>
                    {this.renderLabel()}
                    <input 
                        id={this.getId()}
                        type={this.getType()}
                        onChange={this.onChangeMethod.bind(this)}
                        placeholder={this.getPlaceHolder()}
                        defaultValue={this.props.defaultValue}
                        disabled={this.props.disabled}
                        maxLength={this.state.maxlength}
                    />
                    {this.renderError()}
                </div>
                
            </Provider>
        );
    }
}

export {InputField};
