import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from '../../reducers';

import '../../style/css/hambergerMenu.css';

class HambergerIcon extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            mouseOver: false,
            mouseClick: false
        };
    }

    onClickMenu() {
        if(this.state.mouseClick) {
            this.setState({mouseClick: false});
        } else {
            this.setState({mouseClick: true});
        }
        
        this.props.hideMenuCallBack();
    }

    onMouseOverMenu() {
        this.setState({mouseOver: true})
    }

    onMouseOutMenu() {
        this.setState({mouseOver: false})
    }

    bar1MouseOverClass() {
        if(!this.state.mouseClick
            && this.state.mouseOver) {
            return 'rotateArrowTop';
        }
        

        if(this.state.mouseClick) {
            return 'rotateCrossTop';
        }

        return null;
    }
    bar2MouseOverClass() {
        if(this.state.mouseOver) {
            return 'disappear';
        }

        if(this.state.mouseClick) {
            return 'disappear';
        }

        return null;
    }
    bar3MouseOverClass() {
        if(!this.state.mouseClick
            && this.state.mouseOver) {
                return 'rotateArrowBottom';
        }

        if(this.state.mouseClick) {
            return 'rotateCrossBottom';
        }

        return null;
    }

    render() {
        const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));

        return (
            <Provider store={store} >
                <div className="ham_menu" 
                    onClick={this.onClickMenu.bind(this)} 
                    onMouseOver={this.onMouseOverMenu.bind(this)}
                    onMouseOut={this.onMouseOutMenu.bind(this)}
                >
                    <div className={`bar bar1 ${this.bar1MouseOverClass()}`} />
                    <div className={`bar bar2 ${this.bar2MouseOverClass()}`} />
                    <div className={`bar bar3 ${this.bar3MouseOverClass()}`}/> 
                </div>
            </Provider>
        );
    }
}

export default HambergerIcon;
