/*
    <BackgroundImage 
        className='slide1'
        image={landingImage}
        // fullWindow={false}
    >
*/
import React from 'react';

const BackgroundImage = ({
    className,
    image,
    children,
    fullWindow = true
}) => {
    return (
        <div 
            className={className} 
            style={backgroundWraperStyle(image, fullWindow)}
        >
            {children}
        </div>
    );
};

const backgroundWraperStyle = (img, fullWindow) => {    
    if(fullWindow) {
        return {
            backgroundColor: 'black',
            width: '100%',
            minHeight: '100vh',
            backgroundImage: `url(${img})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            // backgroundPosition:'10px center',
            backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
        };
    }

    return {
        backgroundColor: 'black',
        backgroundImage: `url(${img})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
    };
    
}

export {BackgroundImage};
