import {
    SUBMIT_CREATE_USER,
  } from '../actions/Types';

const INITIAL_STATE = {
    reducerCreateUserRes: [],
};

export default(state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SUBMIT_CREATE_USER:     
            // console.log(action.payload); 
            return { ...state, 
            reducerCreateUserRes: action.payload
        };
  
        default:
        return state;
    }
};