/*  
    == caller with preset
    <OnScrollAnimation 
        data='fromRight'
        className="section2 reverce_text" 
    >
        // content
    </OnScrollAnimation>

    == custom data
    <OnScrollAnimation 
        data={customBlur}
        customData
    >

    const customBlur = [
        {
            start: '0',
            end: '600',
            endOffset: 300,
            properties: [
            {
                startValue: 0,
                endValue: 20,
                property: "blur"
            },
            ],
        },
    ];

*/

import React from 'react';
import Plx from 'react-plx';


const OnScrollAnimation = (props) => {
    const {
        className,
        data,
        customData,
        children,
        style
    } = props;

    return (
            <Plx 
                parallaxData={ getData(data, customData) }
                style= {style}
                className={className} 
            >
                {children}
            </Plx>
    );
};

const getData = (key, customData) => {

    if(customData) return key;

    // console.log(key);
    switch (key) {
        case 'fromLeft':
            return fromLeft;    

        case 'fromLeftFast':
            return fromLeftFast;    

        case 'fromRight':
            return fromRight;  

        case 'fromRightSlow':
            return fromRightSlow;    

        case 'fromRightFast':
            return fromRightFast;    

        case 'fromRightVeryFast':
            return fromRightVeryFast;    

        case 'fromLeftVeryFast':
            return fromLeftVeryFast;    

        case 'fromUp':
            return fromUp;    
        case 'fromUpSlow':
            return fromUpSlow;    
        case 'fromUpFast':
            return fromUpFast;    
        case 'fromUpVeryFast':
            return fromUpVeryFast;    

        case 'fromDown':
            return fromDown;    
        case 'fromDownSlow':
            return fromDownSlow;    
        case 'fromDownFast':
            return fromDownFast;    
        case 'fromDownVeryFast':
            return fromDownVeryFast;    

        case 'blurIn':
            return blurIn;    
    
        default:
            return fadeIn;
    }
}

const shortDistance =  150;

const fadeIn = [
  {
    start: 'self',
    end: 'self',
    endOffset: 500,
    properties: [
      {
        startValue: .1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

const fromRight = [
  {
    start: 'self',
    end: 'self',
    endOffset: 500,
    properties: [
      {
        startValue: shortDistance,
        endValue: 0,
        property: 'translateX',
      },
      {
        startValue: .1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

const fromRightSlow = [
  {
    start: 'self',
    end: 'self',
    endOffset: 800,
    properties: [
      {
        startValue: shortDistance,
        endValue: 0,
        property: 'translateX',
      },
      {
        startValue: .1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

const fromRightFast = [
  {
    start: 'self',
    end: 'self',
    endOffset: 300,
    properties: [
      {
        startValue: shortDistance,
        endValue: 0,
        property: 'translateX',
      },
      {
        startValue: .1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

const fromRightVeryFast = [
  {
    start: 'self',
    end: 'self',
    endOffset: 200,
    properties: [
      {
        startValue: shortDistance,
        endValue: 0,
        property: 'translateX',
      },
      {
        startValue: .1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

// from up
const fromUp = [
  {
    start: 'self',
    end: 'self',
    endOffset: 500,
    properties: [
      {
        startValue: -shortDistance,
        endValue: 0,
        property: 'translateY',
      },
      {
        startValue: .1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

const fromUpSlow = [
  {
    start: 'self',
    end: 'self',
    endOffset: 800,
    properties: [
      {
        startValue: -shortDistance,
        endValue: 0,
        property: 'translateY',
      },
      {
        startValue: .1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

const fromUpFast = [
  {
    start: 'self',
    end: 'self',
    endOffset: 300,
    properties: [
      {
        startValue: -shortDistance,
        endValue: 0,
        property: 'translateY',
      },
      {
        startValue: .1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

const fromUpVeryFast = [
  {
    start: 'self',
    end: 'self',
    endOffset: 200,
    properties: [
      {
        startValue: -shortDistance,
        endValue: 0,
        property: 'translateY',
      },
      {
        startValue: .1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

//from down
const fromDown = [
  {
    start: 'self',
    end: 'self',
    endOffset: 500,
    properties: [
      {
        startValue: shortDistance,
        endValue: 0,
        property: 'translateY',
      },
      {
        startValue: .1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

const fromDownSlow = [
  {
    start: 'self',
    end: 'self',
    endOffset: 800,
    properties: [
      {
        startValue: shortDistance,
        endValue: 0,
        property: 'translateY',
      },
      {
        startValue: .1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

const fromDownFast = [
  {
    start: 'self',
    end: 'self',
    endOffset: 300,
    properties: [
      {
        startValue: shortDistance,
        endValue: 0,
        property: 'translateY',
      },
      {
        startValue: .1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

const fromDownVeryFast = [
  {
    start: 'self',
    end: 'self',
    endOffset: 200,
    properties: [
      {
        startValue: shortDistance,
        endValue: 0,
        property: 'translateY',
      },
      {
        startValue: .1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];


const fromLeft = [
  {
    start: 'self',
    end: 'self',
    endOffset: 500,
    properties: [
      {
        startValue: -shortDistance,
        endValue: 0,
        property: 'translateX',
      },
      {
        startValue: .1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

const fromLeftFast = [
  {
    start: 'self',
    end: 'self',
    endOffset: 300,
    properties: [
      {
        startValue: -shortDistance,
        endValue: 0,
        property: 'translateX',
      },
      {
        startValue: .1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

const fromLeftVeryFast = [
  {
    start: 'self',
    end: 'self',
    endOffset: 200,
    properties: [
      {
        startValue: -shortDistance,
        endValue: 0,
        property: 'translateX',
      },
      {
        startValue: .1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

const blurIn = [
  {
    start: 'self',
    end: 'self',
    endOffset: 300,
    properties: [
      {
        startValue: 20,
        endValue: 0,
        property: "blur"
      },
    ],
  },
];



export {OnScrollAnimation};
