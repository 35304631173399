/*
    ////////========== DEPENDENCY
    //name
    react image crop

    //link
    https://www.npmjs.com/package/react-image-crop

    //install
    npm i react-image-crop

    //import
    import ReactCrop from 'react-image-crop';
    import 'react-image-crop/dist/ReactCrop.css';

    ////////======== caller
    <ImageCrop  
        id="profile"
        callBack={this.onFieldUdate.bind(this)}
        required
        forceValidation={this.state.validate_form}
        imageQuality={.3}
        crop={profileCropDetails}
    />

    // for more image crop details options check the react-image-crop help
    const profileCropDetails = {
        aspect: 1/1,
        x: 0,
        y: 0,
        width: 300
        // height: 200
    }
    
*/

import React, { Component } from 'react';
import ReactCrop from 'react-image-crop';

import {
    extractImageFileExtensionFromBase64,
    base64StringtoFile,
    downloadBase64File,
    image64toCanvasRef
} from "../externalLib/ImageUtility";
import { stateIsTrue } from '../';

import 'react-image-crop/dist/ReactCrop.css';

class ImageCrop extends Component {
    constructor(props) {
        super(props)
        this.imageCanvasRef=React.createRef()
        this.state = { 
            imgSrc: '',
            crop: {
                // aspect: 1/1,
                x: 0,
                y: 0,
                width: 200,
                height: 200
            },
            imageSelected: false,
            outputFile: '',
            confirmEditShow: false,
            showReactCrop: true,
            croppedPixels: {},
            fileExtention: '',
            canvasIsReady: false,
            showRemove: false,
            errorMessage: 'This field is required',
            fileName: '',
            error: false,
            fileType: '',
            fileExtention: '',
            imageQualityProp: .5,
            maxFileSize: 5,
            inputFile: '',
            validateImage: false,
            imageDimention: '',
            returnObj: {},
            fileValidated: false,
            error: false,
            fileIsValid: false,
            newFileName: ''
        }

    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate() {
        if(this.state.canvasIsReady) {
            this.provideImageOutput();
        }

        if(this.state.validateImage) {
            this.filePreCommitValidation();
            this.setState({validateImage: false})
        }

        if(this.state.fileValidated) {
            this.handleValidationResponse();
            this.setState({fileValidated: false});
        }
    }

    componentWillReceiveProps (newProps) {
        // console.log(newProps);
        if(stateIsTrue(newProps.forceValidation)) {
            this.validateAndRespond();
        }
    }

    init() {
        const newFileName = `${Math.floor(Date.now() / 1000)}${Math.round(Math.random()*9999)}`;
        this.setState({newFileName: newFileName})
        //posible bug: if math.round produces same number - then the old file will be replaced

        if(stateIsTrue(this.props.imageQuality)) {
            this.setState({imageQualityProp: this.props.imageQuality})
        }
        
        if(stateIsTrue(this.props.crop)) {
            this.setState({crop: this.props.crop})
        }
    }

    onChangeHandler(event) {
        // console.log('onchange handler');
        if(event.target.files && event.target.files.length > 0) {
            const myFile = event.target.files[0];

            //rename file for source submission
            const fileNameText = this.state.newFileName;
            const fileExtention = myFile.type.split('/')[1];
            const fileName = `${fileNameText}.${fileExtention}`;
            const reNamedFile = new File([myFile], fileName, {type: myFile.type});
            
            this.setState({
                fileType: myFile.type,
                fileExtention: myFile.type.split('/')[1],
                inputFile: reNamedFile,
                fileName: myFile.name
            })
            const myFileItemRender = new FileReader();
            myFileItemRender.addEventListener("load", () => {
                const myResult = myFileItemRender.result;
                this.setState({imgSrc: myResult})
            }, false)

            myFileItemRender.readAsDataURL(myFile);

            // this.setState({imageSelected: true})
            this.setState({validateImage: true})
        }
    }

    onImageLoaded(image) {
        /*  This is called when the required image is loaded, this is usefull if you are
            getting an image from a server directly and cropping it for display at runtime */
        // console.log(image);
    }

    onChangeReactCrop(crop) {
        this.setState({
            crop:crop,
            confirmEditShow: true
        });
    }
    onCropComplete(crop, pixelCrop) {
        if(this.state.error) return null;

        const canvasRef = this.imageCanvasRef.current;
        const sourceImg = this.state.imgSrc;
        image64toCanvasRef(canvasRef, sourceImg, pixelCrop);

        // this.handleCommitImage.bind(this, pixelCrop);
        this.setState({croppedPixels: pixelCrop})
    }

    onConfirmEdit() {
        this.provideImageOutput();
        this.setState({
            confirmEditShow: false,
            showReactCrop: false
        })
    }

    filePreCommitValidation() {
        const myFile = this.state.inputFile;
        const fileSizeBites = myFile.size;
        const maxSize = this.state.maxFileSize;
        const maxSizeKb = (maxSize*1000);
        const maxSizeBites = (maxSizeKb*1000);

        const rv = { error: false }
        
        if(fileSizeBites > maxSizeBites) {
            //file is large
            rv.error = true;
            rv.message = `file size should be less than ${maxSize} MB`;
        }

        const checkWidthHeight = () => {

            const validateDimention = (dimention) => {
                if(stateIsTrue(this.props.minWidth)) {
                    if(dimention.width < this.props.minWidth) {
                        rv.error = true;
                        rv.message = `file width should be atleast ${this.props.minWidth} px in width`;
                    }
                }

                this.setState({
                    returnObj: rv,
                    fileValidated: true
                })
            }

            const callBackOnLoad = (img) => {
                const rv ={
                    width: img.width,
                    heigh: img.height
                }
                validateDimention(rv);
                this.setState({imageDimention: rv})
            }

            //exec
            const renderFile = URL.createObjectURL(this.state.inputFile);
            const img = new Image();
            img.onload = callBackOnLoad.bind(this, img)
            img.src = renderFile;

            // this.setState({
            //     renderFile: renderFile
            // })
        }

        if(!rv.error) {
            checkWidthHeight();
        }

        this.setState({
            returnObj: rv,
            fileValidated: true,
            errorMessage: rv.message
        })
    }

    handleValidationResponse() {
        const rObj = this.state.returnObj;
        if(rObj.error) {
            this.setState({
                error: true,
                showReactCrop: false
            })
            // this.callBackToParent();
        } else {
            this.setState({
                fileIsValid: true,
                imageSelected: true
            })            
            // this.callBackToParent();
        }
    }

    validateAndRespond() {
        let errorValue = false;
        if(stateIsTrue(this.props.required)) {
            if(this.state.imgSrc === ''
                || this.state.error) {
                errorValue = true;
                this.setState({error: true})
            }
        }

        // const rv = {
        //     value: this.state.outputFile,
        //     id: this.props.id,
        //     error: errorValue
        // }

        const rv = {
            value: {
                cropped: this.state.finalFile,
                source: this.state.inputFile
            },
            id: this.props.id,
            error: errorValue
        }

        this.props.callBack(rv);
       
    }

    provideImageOutput() {
        const imageSrc = this.state.imgSrc;
        // const fileExtention = extractImageFileExtensionFromBase64(imageSrc);
        // const fileNameText = Math.floor(Date.now() / 1000);
        const fileNameText = this.state.newFileName;
        const fileName = `${fileNameText}.${this.state.fileExtention}`;
        // const fileName = this.state.fileName;
        const canvasRef = this.imageCanvasRef.current;

        //get image data from canvas
        // console.log(this.state.imageQualityProp);
        const imageData64 = canvasRef.toDataURL(this.state.fileType, this.state.imageQualityProp);

        //file for uplaod
        const newCroppedFile = base64StringtoFile(imageData64, fileName);
        this.setState({finalFile: newCroppedFile, canvasIsReady: false});
        this.setState({
            showRemove: true,
            outputFile: newCroppedFile
        })

        const rv = {
            value: {
                cropped: newCroppedFile,
                source: this.state.inputFile
            },
            id: this.props.id,
            error: false
        }
        this.parentCallBack(rv)
        
        //file for download
        // downloadBase64File(imageData64, fileName);

        this.setState({error: false})
    }

    parentCallBack(obj) {
        this.props.callBack(obj);
    }

    onRemove() {
        let errorValue = false;
        if(stateIsTrue(this.props.required)) {
            errorValue = true;
            this.setState({error: true})
        }

        const rv = {
            value: '',
            id: this.props.id,
            error: errorValue
        }

        this.props.callBack(rv);
        this.resetState();
    }

    resetState() {
        //clear preview canvas
        if(!this.state.error) {
            const canvas = this.imageCanvasRef.current;
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
        

        //reset sate
        this.setState({
            imgSrc: '',
            imageSelected: false,
            finalFile: '',
            confirmEditShow: false,
            showReactCrop: true,
            croppedPixels: {},
            fileExtention: '',
            canvasIsReady: false,
            showRemove: false,
            inputFile: '',
            validateImage: false,
            imageDimention: '',
            returnObj: {},
            fileValidated: false,
            error: false,
            fileIsValid: false,
            fileName: ''
        })
    }

    renderConfirmEdit() {
        if(this.state.confirmEditShow) {
            return (
                <div className="confirm button" onClick={this.onConfirmEdit.bind(this)} >
                    Confirm Edit
                </div>
            )
        }
    }

    renderInput() {
        if(!this.state.imageSelected
            && !this.state.error) {
            return (
                <input 
                    type="file" 
                    name="file" 
                    onChange={this.onChangeHandler.bind(this)}

                />
            )
        }

        return null;
    }

    renderReactCrop() {
        if(this.state.showReactCrop) {
            return (
                <ReactCrop 
                    src={this.state.imgSrc} 
                    crop={this.state.crop} 
                    onChange={this.onChangeReactCrop.bind(this)}
                    onImageLoaded={this.onImageLoaded.bind(this)}
                    onComplete={this.onCropComplete.bind(this)}
                />
            )
        }

        return null;
    }

    renderPreview() {
        if(this.state.imageSelected) {
            return (
                <canvas ref={this.imageCanvasRef}></canvas>
            )
        }
    }
    
    renderRemove() {
        if(this.state.showRemove) {
            return (    
                <div className="remove_wrapper" onClick={this.onRemove.bind(this)}>
                    Remove
                </div>
            )
        }
    }

    renderError() {
        if(this.state.error) {
            return (
                 <div className="input imageUploadInput error_wrapper animated pulse error" >
                    <div className="error" >
                        {this.state.errorMessage}
                    </div>
                    
                    <div className="button" onClick={this.resetState.bind(this)}>
                        Try again
                    </div>
                </div>
            )
        }

        return null;
    }

    renderDisplay() {
        return (
            <div className="displayWrapper" >
                {this.renderReactCrop()}
                {this.renderPreview()}  
            </div>
        )
    }


    render() {
        // console.log(this.state);
        return (
            <div className="imageCropWrapper" >
                {this.renderInput()}
                {this.renderDisplay()}
                {this.renderConfirmEdit()}
                {this.renderRemove()}
                {this.renderError()}
                {/* {this.renderPreview()} */}
                
            </div>
        );
    }
}

export {ImageCrop};
