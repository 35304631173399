import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from "react-router-dom";
import {stateIsTrue} from './';

const TokenCheck = () => {
    if(!stateIsTrue(localStorage.getItem('token'))) {
        return <Redirect to="/" />
    } 

    if(localStorage.getItem('token') !== '') {
        return null;
    }

    return <Redirect to="/" />
};

export {TokenCheck};
