import React, { Component } from "react";
import { Header, Footer } from "./boilerplates";
import "../style/css/film.css";
import Catalogue from "./Catalogue";
import FilmBanner from "./FilmBanner";

class Films extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateName: false,
        };
    }

    /** lifecycle events */

    /** user events */

    /** helper functions */

    /** renders */
    render() {
        return (
            <div className="fimlsPageWrapper">
                <Header showClap />
                {/* {this.renderBanner()} */}
                <FilmBanner />
                <Catalogue />
                <Footer />
            </div>
        );
    }
}

export default Films;
