// import React from 'react';

// const basePath = "http://localhost:4000/";
const basePath = "https://api.alokeshetty.com/";

const urls = {
    films: `${basePath}api/serve/films`,
    filmsTotal: `${basePath}api/serve/films/total`,
    filmsBanner: `${basePath}api/serve/films/banner`,

    talkSubmit: `${basePath}api/email/talk`,

    imagePath: `${basePath}images/`,
};

export { urls };
