import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from '../../../reducers';
import {
    stateIsTrue,
    convertDateToUnix
} from '../'

class EmailField extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            value: '',
            error: false,
            errorMessage: "",
            textreadyToValidate: false,
            showLabel: false
        };
    }

    componentDidUpdate() {
        if(!this.state.showLabel) {
            if(this.state.value !== '') {
                if(!this.props.donotShowlabel) {
                    this.setState({showLabel: true})
                }
            }
        }
    }

    componentWillReceiveProps (newProps) {
        // console.log(newProps);
        if(stateIsTrue(newProps.forceValidation)) {
            this.validateAndRespond(this.state.value, true);
        }
    }

    getClasses() {
        let classs = "input_field email_field";
        if(stateIsTrue(this.props.className)) {
            classs = `${classs} ${this.props.className}`;
        }

        if(this.state.error) {
            classs = `${classs} error required`;
        }

        return classs;
    }

    getPlaceHolder() {
        if(stateIsTrue(this.props.placeHolder)) {
            return this.props.placeHolder;
        }
        
        return "Email";
    }

    onBlurMethod() {
        if(!this.state.textreadyToValidate) {
            this.setState({textreadyToValidate:true})
            this.validateAndRespond(this.state.value, true);
        }
    }

    onChangeMethod(elem) {
        const value = elem.target.value;
        this.setState({value: value})
        this.validateAndRespond(value)
    }

    validateAndRespond(v, force) {
        // update the value so force validation doesnt show default value as error
        let value = v;
        if(!value && stateIsTrue(this.props.defaultValue)) {
            value = this.props.defaultValue;
            this.setState({value:value})
        }

        const rv = {};
        rv.value = value;
        rv.error = false;
        
        const checkEmail = () => {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(value).toLowerCase())) {
                this.setState({error:true});
                this.setState({errorMessage: "Please enter valid email address" })
                rv.error = true;
            } else {
                if(this.state.error) {
                    this.setState({error:false});
                    this.setState({errorMessage: ''});
                }
                rv.error = false;
            }
        }

        if(this.state.textreadyToValidate
            || force) {
            if(stateIsTrue(this.props.required)) {
                if(this.props.required) {
                    if(value === '') {
                        this.setState({error:true});
                        this.setState({errorMessage: "Please enter email address" })
                        rv.error = true;
                    }else {
                        checkEmail();
                    }
                }else {
                    checkEmail();
                }
            }else {
                if(value!="") {
                    checkEmail();
                } else {
                    if(this.state.error) {
                        this.setState({error:false});
                        this.setState({errorMessage: ''});
                    }
                    rv.error = false;
                }
            }
        }

        if(stateIsTrue(this.props.callBack)) {
            // rv.id ="id is not specified";
            rv.id = convertDateToUnix(new Date());
            if(stateIsTrue(this.props.id)) {
                rv.id = this.props.id
            }
            this.props.callBack(rv);
        }
    }

    getId(){
        if(stateIsTrue(this.props.id)) {
            return this.props.id;
        }

        return new Date();
    }

    renderError() {

        if(this.state.error) {
            return (
                <div className="error_wrapper" >
                    {this.state.errorMessage}
                </div>
            )
        }

        return null;
    }

    renderLabel() {
        if(stateIsTrue(this.state.showLabel)) {
            return (
                <div className="label_wrapper" >
                    {this.props.placeHolder}
                </div>
            )
        }

        return null;
    }

    render() {
        // console.log(this.state.value);
        const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));

        return (
            <Provider store={store} >
                <div className={this.getClasses()}>
                    {this.renderLabel()}
                    <input 
                        id={this.getId()}
                        type="email"
                        onChange={this.onChangeMethod.bind(this)}
                        onBlur={this.onBlurMethod.bind(this)}
                        placeholder={this.getPlaceHolder()}
                        defaultValue={this.props.defaultValue}
                    />
                    {this.renderError()}
                </div>
                
            </Provider>
        );
    }
}

export {EmailField};
