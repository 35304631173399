import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { getUrls, isActivePage, stateIsTrue } from "../common";
import "../../style/css/header.css";
import "../../style/lib/animationStyles.css";
import HambergerIcon from "./HambergerIcon";

const clapImg = require("../../assets/graphics/clap.png");
const menuIcon = require("../../assets/icons/menu.png");
const closeIcon = require("../../assets/icons/close.png");

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenu: false,
        };
    }

    /** lifecycle events */

    /** user events */
    onHamburger() {
        // console.log('oncli');
        this.setState({ showMenu: true });
    }

    onClose() {
        this.setState({ showMenu: false });
    }

    /** helper functions */

    /** renders */
    renderMenu() {
        const getClass = (path) => {
            let thisPath = path;

            const urls = getUrls();
            let active = "notActive";
            if (urls.pageName === "/") {
                /** handle landing as films */
                if (path === "/films") active = "active";
            } else {
                /** handle default cases */
                active = isActivePage(thisPath) ? "active" : "notActive";
            }

            return `menuItem h2 ${active}`;
        };

        return (
            <div className="menu">
                <NavLink to="/talk" className={getClass("/talk")}>
                    TALK
                </NavLink>
                <NavLink to="/about" className={getClass("/about")}>
                    ABOUT
                </NavLink>
                <NavLink to="/films" className={getClass("/films")}>
                    FILMS
                </NavLink>
            </div>
        );
    }

    renderHambergure() {
        if (this.state.showMenu) {
            return (
                <div
                    className="hamburgerWrapper"
                    onClick={this.onClose.bind(this)}
                >
                    <img src={closeIcon} alt="" />
                </div>
            );
        }
        return (
            <div
                className="hamburgerWrapper"
                onClick={this.onHamburger.bind(this)}
            >
                <img src={menuIcon} alt="" />
            </div>
        );
    }

    renderName() {
        return (
            <div className="name">
                <div className="menuItem h2">ALOKE SHETTY</div>
                <div className="small">Director</div>
            </div>
        );
    }

    renderClap() {
        if (stateIsTrue(this.props.showClap)) {
            return (
                <div className="menu clapIcon">
                    <img src={clapImg} alt="" />
                </div>
            );
        }

        return null;
    }

    renderDropDownMenu() {
        if (this.state.showMenu) {
            const getClass = (path) => {
                let thisPath = path;

                const urls = getUrls();
                let active = "notActive";
                if (urls.pageName === "/") {
                    /** handle landing as films */
                    if (path === "/films") active = "active";
                } else {
                    /** handle default cases */
                    active = isActivePage(thisPath) ? "active" : "notActive";
                }

                return `menuItem h2 ${active}`;
            };

            return (
                <div className="dropMenu animated fadeIn">
                    <NavLink to="/talk" className={getClass("/talk")}>
                        TALK
                    </NavLink>
                    <NavLink to="/about" className={getClass("/about")}>
                        ABOUT
                    </NavLink>
                    <NavLink to="/films" className={getClass("/films")}>
                        FILMS
                    </NavLink>
                </div>
            );
        }

        return null;
    }

    render() {
        return (
            <div className="headerWrapper">
                <div className="content">
                    <div className="mobileMenu">
                        {this.renderHambergure()}
                        {this.renderDropDownMenu()}
                    </div>
                    {this.renderMenu()}
                    {this.renderName()}
                </div>
                {/* {this.renderClap()} */}
            </div>
        );
    }
}

export { Header };
