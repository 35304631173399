import React, { Component } from 'react';
import { 
    PhoneField,
    InputField,
    EmailField,
    TextAreaField,
    DropDownField,
    FormDataValidation,
    stateIsTrue,
    SpinnerLine,
    PostDataDirect,
    getDataFromServerResponseNonData,
    convertFormObjToPostObj,
    DatePickerDropDown,
    getYearMinus,
    ImageUploadWithSubmit
} from './common';
import {urls} from './constant/urls';

const successIcon = require("../assets/icons/success.png")
const failedIcon = require("../assets/icons/failed.png")


let formDataObj = {};

class TalkForm extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            validate_form: false,
            submitingData: false,
            success: false,
            failed: false
        };
    }

    /** lifecycle events */
    componentDidUpdate() {
        if(this.state.validate_form) {
            this.handleFormSubmit();
            this.setState({validate_form: false})
        }
    }

    /** user events */
    onFieldUdate(obj) {
        // console.log(obj);
        formDataObj[obj.id] = {
            value: obj.value,
            error: obj.error,
        }
    }

    OnSubmit() {
        this.setState({
            validate_form: true,
            submitingData: true
        });
    }

    /** helper methods */
    handleFormSubmit() {
        const hasError = FormDataValidation(formDataObj);
        if(!hasError) {
            /** validation passed, submit form */
            this.submitForm();
        } else {
            /** Validation failed, nothing to do, 
             * the input field will show erriors by default */
            this.setState({submitingData: false})
        }
        
    }

    submitForm() {
        const url = urls.talkSubmit;
        const postObj = convertFormObjToPostObj(formDataObj);
        // console.log(postObj);
        const callBackFucntion = (res) => {
            const result = getDataFromServerResponseNonData(res);
            if(result.success) {
                this.setState({success:true})
            } else {
                this.setState({failed:true})
            }

            this.setState({submitingData: false})
        }
        // console.log(postObj);
        PostDataDirect(url, postObj, callBackFucntion.bind(this));
    }

    renderForm() {
        const renderSubmit = () => {
            if(this.state.submitingData) {
                return (
                    <div className="submit_wrapper" >
                        <SpinnerLine />
                    </div>
                )
            }
    
            return (
                <div className="submit_wrapper" >
                    <div className="button h2" onClick={this.OnSubmit.bind(this)}>
                        Send
                    </div>
                </div>
            )
        }

        return (
            <div className="form_holder" >

                <div className="fiedset removeleft" >
                    <TextAreaField 
                        id="message"
                        placeHolder="Say Hello"
                        required
                        forceValidation={this.state.validate_form}
                        callBack={this.onFieldUdate.bind(this)}
                        donotShowlabel
                    />
                </div>

                <div className="fiedset" >
                    <InputField 
                        id="name"
                        placeHolder="Name"
                        required
                        forceValidation={this.state.validate_form}
                        callBack={this.onFieldUdate.bind(this)}
                        donotShowlabel
                    />
                </div>

                <div className="fiedset" >
                    <PhoneField 
                        id="phone"
                        placeHolder="Phone"
                        required
                        forceValidation={this.state.validate_form}
                        callBack={this.onFieldUdate.bind(this)}
                        donotShowlabel
                    />
                </div>

                <div className="fiedset" >
                    <EmailField 
                        id="email"
                        placeHolder="email"
                        required
                        forceValidation={this.state.validate_form}
                        callBack={this.onFieldUdate.bind(this)}
                        donotShowlabel
                    />
                </div>

                <div className="fiedset" >
                    {renderSubmit()}
                </div>
            </div>
        )
    }

    renderSuccess() {
        if(this.state.success) {
            return (
                <div className="form_holder resultWrapper" >
                    <div className="icon" >
                      <img 
                          src={successIcon}
                          alt=""
                      />
                    </div>
                    Thank You!!
                </div>
            )
        }

        return null;
    }

    renderFailed() {
        if(this.state.failed) {
            return (
                <div className="form_holder resultWrapper" >
                    <div className="icon" >
                        <img 
                            src={failedIcon}
                            alt=""
                        />
                    </div>
                    Sorry Something went wrong
                </div>
            )
        }

        return null;
    }

    renderDecide() {
        if(this.state.success) return this.renderSuccess();
        if(this.state.failed) return this.renderFailed();

        return this.renderForm();
    }

    render() {
        return (
            <div className="formWrapper talkFormWrapper" >
                {this.renderDecide()}
            </div>
        );
    }
}

export default TalkForm;
