/*
    ////== sample field object
    <DocumentView 
        docUrl={this.props.viewUrl}
        docId={this.state.showViewId}
        fieldObj={this.props.viewFields}
    />

    const viewFieldObj = [
        {
            label: "School Name",
            field: "schoolName"
        },
        {
            label: "City",
            field: {
                key: 'city',
                nestedKeys: ['name','state']
            }
        },
        {
            label: "Active",
            field: "active",
            type: 'boolean'
        },
    ]

    The object above is used to pass value and key to disple.
    Like the first index, you can send in a value for field as field name
    or like the second index, you can send an object if the value is also an object
    in this case this component will fetch the value from the object 
    ( doesnt matter how nested it is, it will still get the object )
    
    key: is the first key to get the object from the data response
    nestedKeys: are keys that you want from the given array

*/

import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from '../../reducers';
import {
    PostDataDirect,
    Pagination,
    stateIsTrue,
    getDataFromServerResponse,
    getThisKeyFromAnyWhereNested,
    getDateFromMongoRes
} from '.';
import { SpinnerLine } from './Spinner';

class DocumentView extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            doc_value: []
        };
    }

    componentWillMount() {
        const postObj = {
            "id": this.props.docId
        };
        this.getDoc(postObj)
    }

    getDoc(postObj) {
        PostDataDirect(
            this.props.docUrl,
            postObj,
            this.onLoadDoc.bind(this)
        )
    }

    onLoadDoc(res) {
        this.setState({doc_value: res})
    }

    renderContent() {
        const viewFieldObj = this.props.fieldObj;

        const renderItem = (key, data, index) => {
            const label = viewFieldObj[key]['label'];
            const field = viewFieldObj[key]['field'];


            const convertValue = (string) => {
                let rv = string;
                
                if(!stateIsTrue(viewFieldObj[key]['type'])) return rv;

                const handleBoolean = (string) => {
                    let rvv = 'No';
                    if(string) rvv = "Yes";
                    return rvv;
                }

                switch (viewFieldObj[key]['type']) {
                    case 'date':
                        rv = getDateFromMongoRes(string)
                        break;
                    case 'boolean':
                        rv = handleBoolean(string)
                        break;
                
                    default:
                        break;
                }

                return rv;
            }
            
            if(!data) {
                return (
                    <div className="error" >
                        looks the data at 0 data[0] is blank - if your using findById, 
                        then asign result like returnObj.response.data = [data]
                    </div>
                )
            }

            const renderField = (value) => {
                /*
                    if value is an array return multiple divs with each index
                    else return the value
                */
                //console.log(value);
                if(typeof(value) === 'object') {
                    return "is object";
                
                }    

                return (
                    <div className="value" >
                        {value}
                    </div>
                )
                
            }

            const renderValue = () => {
                if(typeof(field) === 'object') {
                    // field is an object, field has keys to match
                    return (
                        <div className="value" >
                            {Object.keys(field.nestedKeys).map((key2, index2) => {
                                const keyToFetch = field.nestedKeys[key2];
                                const getObj = getThisKeyFromAnyWhereNested(data[field.key], keyToFetch);    
                                return (
                                    <div key={index2} className="value nested_value" >
                                        {convertValue(getObj[keyToFetch])}
                                    </div>
                                )
                            })}
                        </div>
                    );
                    
                }    

                if(typeof(data[field]) === 'object') {
                    /* the field is an object but the key is not set to an object hence handle asarray */
                    return (
                        <div className="value" >
                            {Object.keys(data[field]).map((key, index) => {
                                return (
                                    <div key={index} className="value nested_value" >
                                        {convertValue(data[field][key])}
                                    </div>
                                )
                            })}
                        </div>
                    )

                }


                return (
                    <div className="value" >
                        {convertValue(data[field])}
                    </div>
                )
            }

            return (
                <div key={index} className="item" >
                    <div className="label body" >
                        {label}
                    </div>
                    <div className="body" >
                        {renderValue()}
                    </div>
                </div>
            )
        }

        const res = getDataFromServerResponse(this.state.doc_value);
        if(res.success) {
            const data = res.data[0];
            return (
                <div className="card view_wrapper" >
                    {Object.keys(viewFieldObj).map((key, index) => {
                        return renderItem(key, data, index)
                    })}
                </div>
            )
        }

        return (
            <div className="card view_wrapper" >
                <SpinnerLine />
            </div>
        )
    }


    render() {
        // console.log(this.state);
        const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));
        return (
            <Provider store={store} >
                {this.renderContent()}
            </Provider>
        );
    }
}

export {DocumentView};
