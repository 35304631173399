/*
    const fieldSet = {
        amount: {
            require: true,
            key: 'amount_value',
            number: true,
        },
        email: {
            require: true,
            key: 'email_vlaue',
            email: true
        },
        project: {
            require: true,
            key: 'project_id',
        }
    };

    const validationResponse = libFormValidation.validateFormData(thisProps, fieldSet);
    thisProps.actionRenderError(validationResponse.summary.error);
*/

const validateFormData = (data, fieldSets) => {
    const errorFields = [];
    errorFields.summary = [];
    errorFields.summary.error = false;
    errorFields.data = [];

    const checkForRequired = (field, keyName) => {   
        // console.log(keyName);
        if (field 
            && field.require
            && data[keyName] === '') {
                errorFields.summary.error = true;
                const fld = field;
                fld.message = 'This field is required';
                // errorFields.data.push(fld);
                errorFields.data[keyName] = fld;
        } 
    };

    const checkForFormats = (field, keyName) => {
        const value = data[keyName];
        if (value !== '') {
            if (field.email) {
                const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                // const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if (!re.test(value)) {
                    errorFields.summary.error = true;
                    const fld = field;
                    fld.message = 'Not a valid email id';
                    errorFields.data[keyName] = fld;
                }
            }

            if (field.number) {
                const re = /^\d+$/;
                if (!re.test(value)) {
                    errorFields.summary.error = true;
                    const fld = field;
                    fld.message = 'Not a valid number';
                    errorFields.data[keyName] = fld;
                }
            }
        }
    };

    Object.keys(fieldSets).map((key, index) => {
        if(fieldSets[key]) {
            checkForRequired(fieldSets[key], key);
            checkForFormats(fieldSets[key], key);
        }
        return null;
    });

    return errorFields;
};


export { validateFormData };
