/*
    <Checkbox 
        required
        callBack = {this.onFieldUdate.bind(this)}
        forceValidation={this.state.validate_form}
        id='agree'
        label="I understand and I have read the terms and conditions"
        errorMessage="To agree to our terms and conditions is required"
        //setPresetTrue
    />

*/

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from '../../../reducers';
import {stateIsTrue} from '../../common';

const checkIcon = require('./support/check.png');
const checkedIcon = require('./support/checked.png');

class Checkbox extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            checked: false,
            error: false
        };
    }

    componentDidMount() {
        if(stateIsTrue(this.props.setPresetTrue)) {
            this.setState({checked:true})
            this.respondToParent(true)
        }
    }

    onCheck() {
        if(this.state.checked) {
            this.setState({checked: false})
            this.respondToParent(false);
        } else {
            this.setState({checked: true})
            this.respondToParent(true);
        }
    }

    componentWillReceiveProps (newProps) {
        // console.log(newProps);
        if(stateIsTrue(newProps.forceValidation)) {
            this.respondToParent(this.state.checked)
        }
    }

    respondToParent(value) {
        
        const requiredCheck = () => {
            let rv = false;
            if(stateIsTrue(this.props.required)) {
                if(!value) {
                    rv = true;
                }
            }

            return rv;
        }

        if(requiredCheck()) {
            this.setState({error: true})
        } else {
            this.setState({error: false})
        }

        const obj = {
            value: value,
            id: this.props.id,
            error: requiredCheck()
        }

        this.props.callBack(obj);
    }

    renderCheckBox() {
        if(this.state.checked) {
            return (
                <div className="checkbox" onClick={this.onCheck.bind(this)}>
                    <img 
                        src={checkedIcon}
                        alt=""
                    />
                </div>
            )
        }

        return (
            <div className="checkbox" onClick={this.onCheck.bind(this)}>
                <img 
                    src={checkIcon}
                    alt=""
                />
            </div>
        )
    }
    renderLabel() {
        if(stateIsTrue(this.props.label)) {
            return (
                <div className="label_wrapper" >
                    {this.props.label}
                </div>
            )
        }

        return null;
    }

    renderError() {
        const getErrorMessage = () => {
            let rv = 'This field is required';
            if(stateIsTrue(this.props.errorMessage)) rv = this.props.errorMessage;
            return rv;
        }   

        if(this.state.error) {
            return (
                <div className="error_wrapper animated pulse" >
                    {getErrorMessage()}
                </div>
            )
        }

        return null;
    }

    render() {
        const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));

        return (
            <Provider store={store} >
                <div className="checkbox_wrapper" >
                    <div className="content" >
                        {this.renderCheckBox()}  
                        {this.renderLabel()}  
                    </div>
                    {this.renderError()}
                </div>
            </Provider>
        );
    }
}

export {Checkbox};
