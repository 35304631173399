import React from 'react';
import './support/spinner.css';



const Spinner = () => {
  return (
    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
  );
};

const SpinnerLine = () => {
  return (
    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  );
};

export { 
  Spinner,
  SpinnerLine
};
