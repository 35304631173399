/*
    <Pagination 
        countUrl={urls.filmsTotal}
        refreshCount={this.state.paginationRefreshCount}
        startTotal={this.state.limit}
        nextIcon={nextIcon}
        prevIcon={prevIcon}
        callBack={this.onPagination.bind(this)}
    />

*/

import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from '../../reducers';
import {
    PostDataDirect,
    stateIsTrue,
    getDataFromServerResponse
} from '.';
import { SpinnerLine } from './Spinner';

import "./support/pagination.css";

class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            totalCountServerResponse: [],
            totalCount: 0,
            currentOffset: 10,
            limit: 10,
            refreshCount: '',
            refreshComponent: false
        };
    }
    
    static getDerivedStateFromProps(nextProps, prevState) {
        // console.log(nextProps);
        // console.log(prevState);
        if(prevState.refreshCount !== nextProps.refreshCount) {
            return {
                refreshCount: nextProps.refreshCount,
                refreshComponent: true
            }
        }
        return null;
    }

    componentDidMount() {
        this.getTotalCount();
        this.init();
    }

    componentDidUpdate(prevProps, preState, snapShop) {

        if(prevProps !== this.props) {
            const oldCount = prevProps.refreshCount;
            const newCount =  this.props.refreshCount;
            if(oldCount != newCount) {
                this.init();
            }
        }

        if(this.props.startTotal !== this.state.limit) {
            // console.log('update limit');
        }
        if(this.state.refreshComponent) {
            // console.log('refresh called');
        }
    }

    init() {
        this.setState({currentOffset: this.props.startTotal})
        this.setState({limit: this.props.startTotal})
    }

    onLoadTotal(res) {
        this.setState({totalCountServerResponse: res})
        const resObj = getDataFromServerResponse(res);
        if(resObj.success) {
            const data = resObj.data;
            if(stateIsTrue(data)) {
                this.setState({totalCount: data})
            }
        }
        
    }

    callBackParent(nOffset, nlimit) {
        // console.log('caleld');
        const obj = {
            skip: nOffset,
            limit: nlimit
        }

        this.setState({currentOffset: nOffset+nlimit});
        this.setState({limit: nlimit})

        this.props.callBack(obj);
    }

    getTotalCount() {
        const postObj = {
            clientKeytoRefreshPagination: this.props.refreshCount
        };
        PostDataDirect(
            this.props.countUrl,
            postObj,
            this.onLoadTotal.bind(this)
        )
    }


    /** render */
    renderNav() {
        
        const {totalCount, currentOffset, limit} = this.state;
        
        const renderPrev = () => {

            const getValue = () => {
                if(stateIsTrue(this.props.prevIcon)) {
                    return (
                        <img 
                            src={this.props.prevIcon}
                            alt=""
                        />
                    )
                }

                return "Prev";
            }

            const getClass = () => {
                let classes = 'prev'
                if(!stateIsTrue(this.props.prevIcon)) {
                    classes = `${classes} button`
                }
                return classes;
            }

            if(currentOffset>limit) {
                const pOffset = currentOffset-(limit*2);
                const plimit = limit;
                return (
                    <div className={getClass()} onClick={() => this.callBackParent(pOffset, plimit)} >
                        {getValue()}
                    </div>
                )
            }

            return null;
        }

        const renderNext = () => {
            const getValue = () => {
                if(stateIsTrue(this.props.nextIcon)) {
                    return (
                        <img 
                            src={this.props.nextIcon}
                            alt=""
                        />
                    )
                }

                return "Next";
            }

            const getClass = () => {
                let classes = 'next'
                if(!stateIsTrue(this.props.nextIcon)) {
                    classes = `${classes} button`
                }
                return classes;
            }

            if(currentOffset < totalCount) {
                const nOffset = currentOffset;
                const nlimit = limit;

                return (
                    <div className={getClass()} onClick={() => this.callBackParent(nOffset, nlimit)}>
                        {getValue()}
                    </div>
                )
            }

            return null;
        }

        const paginationDetails = () => {
            return (
                <div className="total_wrapper" >
                    {this.state.currentOffset} / {this.state.totalCount}
                </div>
            )
        }

        return (
            <div className="nav_wrapper" >
                {renderPrev()}
                {paginationDetails()}
                {renderNext()}
            </div>
        )
        
    }

    renderBar() {
        if(stateIsTrue(this.state.totalCount)
            && stateIsTrue(this.state.currentOffset)) {
            return (
                <div className="pagination_warpper card" >
                    {this.renderNav()}
                </div>
            )
        }

        return (
            <div className="pagination_warpper card" >
                <SpinnerLine/>
            </div>
        )
    }
 
    render() {
        // console.log(this.state);
        const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));
        return (
            <Provider store={store} >
                {this.renderBar()}
            </Provider>
        );
    }
}

export {Pagination};
